@use '/styles/abstracts' as *;

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // grid-template-columns: repeat( auto-fit, minmax(rem(300px), 1fr) );
  grid-auto-rows: 1fr;
  grid-column-gap: rem(20px);
  grid-row-gap: rem(20px);
  @include mq(tablet_md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq(desktop_sm) {
    grid-template-columns: repeat(3, 1fr);
  }

  &--sentiment {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    @include mq(desktop_sm) {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }
}
