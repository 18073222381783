@use '/styles/abstracts' as *;
@use 'sass:math';
.card-body {
  $self: &;
  width: 100%;
  position: relative;
  padding: rem(20px);
  height: rem(260px);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% - rem(40px));
    height: rem(20px);
    background: linear-gradient(rgba($color-white, 0.4), $color-white);
  }

  &__header {
    position: relative;
    @include padding-right(rem(40px + 23px + 15px));

  }

  &__title {
    font-family: $sans-mulish;
    @include responsive-font-size(rem(24px));
    font-weight: $font__weight_light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: rem(-0.25px);
    color: var(--color-ace-black);

    a {
      color: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }

  &__pin-button {
    position: absolute;
    right: rem(-1px);
    top: rem(-3px);
  }

  &__desc {
    font-family: $sans-mulish;
    font-size: rem(15px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: $color-ace-black;
    margin-bottom: 0;

    a,
    a:hover {
      color: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }
}
