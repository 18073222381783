@use '../../abstracts/' as *;
@use 'sass:math';

.hero-partner {
  $self: &;
  background-color: $color-ace-sky;

  &__container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    @include mq(desktop_sm) {
      flex-direction: row;
    }
  }

  &__details,
  &__img {
    width: 100%;
    @include mq(desktop_sm) {
      width: 50%;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    justify-content: space-between;
    order: 2;
    @include mq(desktop_sm) {
      order: 1;
    }
  }

  &__details-header {
    display: flex;
    // min-height: rem(120px);
    position: relative;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(#eceff0, 1); // aka $color-ace-gray, 0.5

    &-logo {
      position: relative;
      display: flex;
      align-items: center;
      width: rem(159px);
      max-width: rem(159px);
      height: 100%;
      padding: rem(20px) rem(15px);
      .next-img-wrap {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        @include mq(desktop_sm) {
          position: relative;
        }
      }
    }

    &-partner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: rem(28px) rem(20px);
      background-color: $color-ace-gray;
      @include mq(desktop_sm) {
        padding: rem(38px) rem(20px);
      }
      p {
        margin: 0;
        font-size: rem(15px);
        font-weight: $font__weight_regular;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        color: var(--color-ace-black);
      }
    }
  }

  &__details-body {
    padding: rem(25px) rem(30px);
    margin-top: auto;

    &-title {
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
      @include responsive-font-size(rem(48px));
    }
  }

  &__details-footer {
    margin-top: auto;
    padding: 0 rem(30px) rem(30px) rem(30px);
    &-link {
      color: inherit;
      font-family: $sans-roboto;
      font-size: rem(15px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-decoration: none;
      text-transform: uppercase;

      .icon {
        transform: translate(rem(4px), rem(-10px));
      }
    }
  }

  &__img {
    position: relative;
    height: 50vw;
    order: 1;
    @include mq(desktop_sm) {
      order: 2;
    }
    @include mq(desktop_md) {
      height: auto;
      padding-bottom: 0.5 * to-percent(420, 720);
    }
  }
}
