@use '../abstracts/' as *;
@use 'sass:math';

.topics-list {
  $self: &;
  @include padding-top(rem(60px));
  padding-left: 0;
  padding-right: 0;
  @include mq(tablet_sm) {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(rem(300px), 1fr) );
    grid-auto-rows: 1fr;
    grid-column-gap: rem(50px);
    grid-row-gap:rem(50px);
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq(desktop_sm) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__list {
    padding: 0;
    list-style: none;
    margin-top: 0;
    margin-right: 0;
    @include margin-bottom(rem(60px));
    margin-left: 0;
    @include mq(tablet_sm) {
      margin-bottom: 0;
    }
  }

  &__topic-item {

    &-label {
      display: block;
      margin-top: 0;
      margin-right: 0;
      @include margin-bottom(rem(25px));
      margin-left: 0;
      padding-bottom: rem(15px);
      @include responsive-font-size(rem(24px));
      font-weight: $font__weight_bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: rem(-0.25px);
      color: $color-ace-black;
      border-bottom: solid rem(1px) $color-text-gray;
    }
  }


  &__topic-list {
    padding: 0;
    list-style: none;
    &-item {
      margin: 0 0 rem(10px) 0;
      @include responsive-font-size(rem(18px));
      font-weight: $font__weight_regular;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $color-ace-sky;
      a {
        color: inherit;
      }
    }

  }


}
