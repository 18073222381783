@use '../abstracts/' as *;
@use 'sass:math';

.search {
  $self: &;
  $search-height-dt: rem(64px);
  $submit-width-dt: rem(120px);
  $submit-reset-gap-dt: rem(20px);
  $reset-width: rem(22px);

  position: relative;
  width:100%;
  height: $search-height-dt;
  @include mq(tablet_lg) {

  }
  &__magnify-wrap {
    position: absolute;
    z-index: get-z-index('search-magnify-wrap');
    left: rem(19px);
    width: rem(32px);
    height: $search-height-dt;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    @include mq(tablet_md) {
      display: flex;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    border: none;
    font-family: $sans-mulish;
    font-weight: $font__weight_light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: rem(-0.25px);
    color: $color-text-gray;
    border-radius: rem(12px);
    font-size: rem(15px);
    padding-left: rem(19px);
    padding-right: ($reset-width * 0.8) + ($submit-reset-gap-dt * 0.8) + ($submit-width-dt * 0.8) + rem(6px);
    @include mq(tablet_md) {
      font-size: rem(24px);
      padding-left: rem(19px + 32px + 14px);
      padding-right: $reset-width + $submit-reset-gap-dt + $submit-width-dt + rem(6px);
    }
    &::placeholder {
      color: #ccc;
    }

  }

  &__submit-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: get-z-index('search-submit-button');
    border-radius: 0 rem(12px) rem(12px) 0;
    text-align: center;
    background-color: $color-ace-sky;
    color: $color-white;
    font-family: $sans-roboto;
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    font-size: rem(15px);
    width: $submit-width-dt * 0.8;
    @include mq(tablet_md) {
      font-size: rem(18px);
      width: $submit-width-dt;
    }
  }

  &__reset-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0; right: 0; bottom: 0;
    z-index: get-z-index('search-reset-button');
    padding: rem(2px);
    height: 100%;
    width: rem(22px);

    cursor: pointer;
    text-align: center;
    appearance: none;
    background: transparent;
    color: $color-ace-black;
    transform: translateX((-(($submit-width-dt * 0.8)) + rem(-20px)));
    span {
      @include sr-only;
    }

    .icon {
      width: 18px;
      height: 18px;
    }
    @include mq(tablet_md) {
      transform: translateX((-($submit-width-dt) + rem(-20px)));
    }
  }

}
