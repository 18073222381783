@use '/styles/abstracts' as *;
@use 'sass:math';

.pin-button {
  width: rem(40px);
  height: rem(40px);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-radius: 100%;

  .unpinned-tack {
    fill: rgba($color-ace-black, 1);
  }

  .pinned-tack {
    fill: rgba($color-white, 0);
  }

  .pinned-circle {
    fill: rgba($color-ace-blue, 0);
  }

  &:hover {
    .unpinned-tack {
      fill: rgba($color-ace-black, 0);
    }

    .pinned-tack {
      fill: rgba($color-ace-blue, 1);
    }

    .pinned-circle {
      fill: rgba($color-ace-blue, 0);
    }
  }

  &--pinned,
  &--pinned:hover {
    .unpinned-tack {
      fill: rgba($color-ace-black, 0);
    }

    .pinned-tack {
      fill: rgba($color-white, 1);
    }

    .pinned-circle {
      fill: rgba($color-ace-blue, 1);
    }
  }
}
