@use '/styles/abstracts' as *;
@use 'sass:math';
.home-billboard {
  $self: &;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 50vw;

    overflow: hidden;
    @include mq(desktop_lg) {
      height: auto;
      min-height: rem(940px);
    }
  }

  &__details {
    position: relative;
    z-index: get-z-index('home-billboard-details');
    padding: rem(30px) rem(10px) rem(40px);
    @include mq(desktop_sm) {
      padding: rem(60px) rem(40px);
    }

    &-title {
      max-width: rem(1264px);
      margin: 0 auto;
      color: $color-white;
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      font-size: rem(128px * 0.3125);

      text-align: center;
      @include mq(tablet_md) {
        font-size: rem(128px * 0.5);
      }
      @include mq(desktop_md) {
        font-size: rem(128px * 0.75);
      }
      @include mq(desktop_lg) {
        font-size: rem(128px);
        letter-spacing: rem(-2.29px);
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: get-z-index('home-billboard-details-img');

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-ace-black, 0.25);
    }
  }
}
