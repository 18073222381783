@use "sass:string";
@use '/styles/abstracts/variables' as *;

@mixin layout-row() {
  width: 100%;
}

@mixin layout-row-inner() {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $site__content_max_width;
}






@mixin layout-gutter-responsive($position: "left-right", $split: null) {
  $left_gutter_mobile: get-gutter("mobile", $split);
  $left_gutter_desktop: get-gutter("desktop", $split);
  $right_gutter_mobile: get-gutter("mobile", $split);
  $right_gutter_desktop: get-gutter("desktop", $split);

  @if string.index($position, "left-right") == null {
    @if string.index($position, "left") {
      // don't give right gutters
      $right_gutter_mobile: null;
      $right_gutter_desktop: null;
    }
    @if string.index($position, "right") {
      // don't give left gutters

      $left_gutter_mobile: null;
      $left_gutter_desktop: null;
    }
    @if string.index($position, "no-gutter") {
      // don't give any/override right gutters
      $right_gutter_mobile: 0;
      $right_gutter_desktop: 0;
      $left_gutter_mobile: 0;
      $left_gutter_desktop: 0;
    }
  }
  padding-left: $left_gutter_mobile;
  padding-right: $right_gutter_mobile;
  @include mq(desktop_sm) {
    padding-left: $left_gutter_desktop;
    padding-right: $right_gutter_desktop;
  }
}

@mixin layout-gutter-responsive-collapse(
  $breakpoint: "mobile",
  $position: "both",
  $split: null
) {
  $left_gutter_mobile: get-gutter("mobile", $split);
  $left_gutter_desktop: get-gutter("desktop", $split);
  $right_gutter_mobile: get-gutter("mobile", $split);
  $right_gutter_desktop: get-gutter("desktop", $split);
  //  only left: mobile
  @if string.index($position, "left") and string.index($breakpoint, "mobile") {
    $left_gutter_mobile: 0;
  } // only left: desktop
  @if string.index($position, "left") and string.index($breakpoint, "desktop") {
    $left_gutter_desktop: 0;
  } //  only right: mobile
  @if string.index($position, "right") and string.index($breakpoint, "mobile") {
    $right_gutter_mobile: 0;
  } // only right: desktop
  @if string.index($position, "right") and string.index($breakpoint, "desktop")
  {
    $right_gutter_desktop: 0;
  } //  both: mobile
  @if string.index($position, "both") and string.index($breakpoint, "mobile") {
    $left_gutter_mobile: 0;
    $right_gutter_mobile: 0;
  } // both: desktop
  @if string.index($position, "both") and string.index($breakpoint, "desktop") {
    $left_gutter_desktop: 0;
    $right_gutter_desktop: 0;
  }

  // FINALLY: output values if mobile
  // ==========================================================================
  padding-left: $left_gutter_mobile;
  padding-right: $right_gutter_mobile;
  @include mq(desktop_sm) {
    padding-left: $left_gutter_desktop;
    padding-right: $right_gutter_desktop;
  }
}
