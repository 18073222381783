@use '/styles/abstracts' as *;
@use 'sass:math';
.home-process {
  $self: &;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    // max-width: rem(960px);
    max-width: rem(1077px + 40px);
    margin: 0 auto;
    padding: rem(30px) rem(20px) rem(60px);
    @include mq(desktop_sm) {
      padding-bottom: rem(150px);
    }
  }

  &__intro {
    max-width: rem(668px);
    margin: 0 auto rem(20px);
    text-align: center;

    p {
      color: $color-ace-black;
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
    }

    &-img {
      display: inline-block;
      margin: 0 0 rem(10px);
      text-align: center;
      transform: rotate(30deg);
    }

    &-title {
      margin: 0 auto rem(20px);
      color: $color-ace-black;
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
      text-align: center;
      @include responsive-font-size(rem(48px));
    }
  }
}
