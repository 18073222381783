@use '../../abstracts/' as *;
@use 'sass:math';

.article-preheader {
  $self: &;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-ace-sky);
  color: $color-white;
  padding: rem(10px) rem(20px) rem(8px) rem(20px);
  font-family: $sans-mulish;
  font-size: rem(15px);
  font-weight: $font__weight_regular;

  &__label {
    display: flex;
    align-items: center;
    min-height: rem(20px);
    &-icon {
      display: inline-flex;
      align-items: center;
      min-height: rem(20px);
      margin-right: rem(10px);
    }

    &-text {
      text-transform: none;
    }
  }

  &__details {
    font-size: rem(15px);

    .divider {
      margin-left: rem(10px);
      margin-right: rem(10px);
    }

    &--ago {
      font-size: rem(13px);
      font-stretch: normal;
      font-style: italic;
      line-height: 1.69;
      letter-spacing: normal;
      color: var(--color-ace-gray);
    }
  }

  &--purple {
    background-color: var(--color-ace-purple);
  }

  &--red {
    background-color: var(--color-ace-red);
  }
}
