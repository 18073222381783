@use '/styles/abstracts' as *;
@use 'sass:math';

.login-spinner {
  $size: 80px;
  $speed: 1.2s;
  $precision: 5;
  $background-color: transparent;
  $foreground-color: $color-ace-sky;
  $radius: $size * 0.35;
  $width: $size * 0.08;
  $height: $size * 0.22;
  color: $foreground-color;
  display: inline-block;
  position: relative;
  width: $size;
  height: $size;
  background-color: $background-color;

  div {
    transform-origin: round($size * 0.5) round($size * 0.5);
    animation: login-spinner $speed linear infinite;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: round($size * 0.5 - $radius - $height * 0.5);
      left: round($size * 0.5 - $width * 0.5);
      width: round($width);
      height: round($height);
      border-radius: rem(4px);
      background: $foreground-color;
    }
    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    @keyframes login-spinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
