@use '/styles/abstracts' as *;
@use 'sass:math';
.home-hero {
  $self: &;

  &__container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    @include mq(desktop_sm) {
      flex-direction: row;
      align-items: stretch;
      //min-height: 500px;
    }
  }

  &__details,
  &__img {
    width: 100%;
    @include mq(desktop_sm) {
      width: 50%;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-ace-teal;
    @include mq(desktop_sm) {
      justify-content: center;
    }
  }

  &__details-body {
    max-width: rem(720px);
    padding: rem(30px) rem(30px) rem(40px);
    @include mq(desktop_sm) {
      padding: rem(60px) rem(40px);
    }

    p {
      color: $color-white;
      @include responsive-font-size(rem(18px));
      font-weight: $font__weight_regular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
    }

    &-actions {
      margin: rem(30px) auto 0 auto;
      text-align: center;
    }

    &-link {
      display: inline-block;
      padding: rem(25px) rem(42px);
      background-color: $color-white;
      border-radius: rem(6px);
      box-shadow: -6px 6px 0px 0px $color-ace-blue;
      color: $color-ace-blue;
      font-family: $sans-roboto;
      @include responsive-font-size(rem(21px));
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
    }

    &-title {
      max-width: rem(520px);
      margin: 0 auto rem(20px);
      color: $color-white;
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
      text-align: center;
      @include font-size(48px);
    }
  }

  &__img {
    position: relative;
    height: strip-unit(to-percent(500, 720)) + vw;
    background-color: $color-ace-teal;
    margin: 0;
    padding: 0;
    span,
    img {
      background-color: $color-ace-teal;
    }
    @include mq(desktop_sm) {
      height: auto;
      padding-bottom: 0.5 * to-percent(500, 720);
    }
  }
}
