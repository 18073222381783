// Adapted from https://css-tricks.com/copy-the-browsers-native-focus-styles/

// @element-state options
// active, focus, focus-within, target, :hover, :visited, :focus-visible
// ===============================================
// EXAMPLE: focus-ring($element-state: false);
// output
// .myClass {
//   outline: 5px auto Highlight;
//   outline: 5px auto -webkit-focus-ring-color;
// }
// ===============================================
// EXAMPLE: focus-ring();
// output
// .myClass:focus {
//   outline: 5px auto Highlight;
//   outline: 5px auto -webkit-focus-ring-color;
// }
// ===============================================
// EXAMPLE:
// @include focus-ring() {
//    background: red;
// };
// output
// .myClass:focus {
//   outline: 5px auto Highlight;
//   outline: 5px auto -webkit-focus-ring-color;
//   background: red;
// }
// ===============================================

@mixin focus-ring(
  $element-state: "focus",
  $size: 5px,
  $style: auto,
  $color: false
) {
  @if ($element-state) {
    &:#{$element-state} {
      @if ($color) {
        outline: $size $style $color;
        @content;
      } @else {
        outline: $size $style Highlight;
        outline: $size $style -webkit-focus-ring-color;
        @content;
      }
    }
  } @else {
    @if ($color) {
      outline: $size $style $color;
      @content;
    } @else {
      outline: $size $style Highlight;
      outline: $size $style -webkit-focus-ring-color;
      @content;
    }
  }
}
