@use '/styles/abstracts' as *;

table {
  width: 100%;
  border-bottom: none;
  border-spacing: 0;
  font-family: $table__font_family;

  caption,
  th,
  td {
    padding: $table__cell_padding-vertical $table__cell_padding-horizontal;
  }

  thead {
    background: $table__head_bg;
    color: $table__head_color;

    th, td {
      border: none;
      padding: $table__head_cell_padding_vertical $table__cell_padding_horizontal;
    }
  }

  tbody {
    color: $table__body_color;

    th, td {
      background-color: $table__body_cell_bg;
    }

    b {
      font-weight: $font__weight_medium;
    }
  }

  tfoot {
    width: 100%;
    background: $table__head_bg;
    color: $table__head_color;

    td, th {
      border: none;
      padding: $table__head_cell_padding_vertical $table__cell_padding_horizontal;
    }
  }
}

