@use '/styles/abstracts' as *;
@use 'sass:math';
.home-start {
  $self: &;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $site__max_width;
    margin: 0 auto;
    padding: rem(30px) rem(10px) rem(80px);
    @include mq(desktop_sm) {
      padding-bottom: rem(150px);
    }
  }

  &__actions {
    margin: rem(30px) auto 0 auto;
    text-align: center;

    &-link {
      display: inline-block;
      padding: rem(25px) rem(42px);
      background-color: $color-ace-teal;
      border-radius: rem(6px);
      box-shadow: -6px 6px 0px 0px $color-ace-blue;
      color: $color-white;
      font-family: $sans-roboto;
      font-size: rem(21px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  &__feature-column {
    max-width: rem(520px);
    @include mq(desktop_sm) {
      width: 25%;
      padding: 0 rem(20px) 0 rem(20px);
    }
    @include mq(desktop_md) {
      padding: 0 rem(45px) 0 rem(45px);
    }
    @include mq($until: desktop_sm) {
      padding-top: rem(60px) !important;
    }
  }

  &__feature-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: rem(400px);
    padding: rem(20px) 0 rem(40px);
    @include mq(desktop_sm) {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      max-width: none;
      padding: rem(100px) 0 rem(60px);
    }
  }

  &__intro {
    max-width: rem(668px);
    margin: 40px auto 0;
    @include mq(desktop_sm) {
      margin-top: 80px;
    }

    p {
      color: $color-ace-black;
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
    }

    &-img {
      display: block;
      margin: 0 auto rem(10px);
      text-align: center;
    }

    &-title {
      margin: 0 auto rem(20px);
      color: $color-ace-black;
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
      text-align: center;
      @include responsive-font-size(rem(48px));
    }
  }
}
