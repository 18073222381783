@use '/styles/abstracts' as *;

.indicate {
  display: inline-flex;
  background-color: $color-ace-teal;
  width: rem(8px);
  height: rem(8px);
  padding: 0;
  border-radius: rem(4.5px);
  margin: 0 rem(3px);
  &[tabindex='0'] {
    width: rem(40px)
  }
  &__sr-text {
    @include sr-only;
  }
}
