@use '../../abstracts/' as *;
@use 'sass:math';

.challenge-card-row {
  $self: &;
  padding-right: rem(20px);
  padding-left: rem(20px);

  &__label {
    font-family: $sans-mulish;
    font-size: rem(24px);
    font-weight: $font__weight_bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: rem(-0.25px);
    color: var(--color-ace-black);
    margin-bottom: rem(18px);
  }
}
