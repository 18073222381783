@use '/styles/abstracts' as *;
@use 'sass:math';
.home-process-step {
  $self: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: rem(520px);
    margin: -50px auto 0 auto;
    text-align: left;

    @include mq(desktop_sm) {
      max-width: unset;
      align-self: flex-start;
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }

  // flex wrapper added to support images
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include mq(desktop_sm) {
      flex-flow: row-reverse;
      justify-content: space-between;
      @include margin-top(20px);
      @include margin-bottom(20px);
    }

    &-caption {
      @include margin-bottom(rem(30px));
      @include mq(desktop_sm) {
        width: to-percent(400px, 1077px);
        max-width: rem(400px);
      }
    }

    &-figure {
      @include mq(desktop_sm) {
        width: to-percent((1077px - 400px), 1077px);
      }
    }

    &--onboarding &-figure,
    &--feedback &-figure {
      > span {
        max-width: rem(560px);
      }
    }

    &--cards &-figure > span {
      max-width: rem(618px);
      @include mq(desktop_sm) {
        margin-left: auto !important
      }
    }

  }

  &__details {
    p {
      color: $color-ace-black;
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
    }

    &-title {
      margin: 0 auto rem(10px);
      color: $color-ace-black;
      font-weight: $font__weight_bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: rem(-0.25px);
      font-size: rem(24px);
      text-align: left;
    }
  }

  &__icon {
    display: block;
    margin: 0 0 rem(10px);
    color: $color-ace-sky;
    @include mq($until: desktop_sm) {
      width: 66px;
      height: 66px;
    }
  }

  &__trail {
    transform: scaleX(-1);
    @include mq(desktop_sm) {
      transform: translateX(-70px) scaleX(-1);
    }
  }

  &--alt {
    #{$self}__container {
      @include mq(desktop_sm) {
        align-self: flex-end;
      }
    }
    #{$self}__content {
      @include mq(desktop_sm) {
        flex-flow: row;
      }
    }

    #{$self}__trail {
      transform: none;
      @include mq(desktop_sm) {
        transform: translateX(-70px);
      }
    }
  }

  &--first {
    #{$self}__trail {
      transform: translateX(-20px) scaleX(-1);
      @include mq(desktop_sm) {
        transform: translateX(-50%);
      }
    }
  }
}
