@use '../abstracts/' as *;
@use 'sass:math';

.topics-filter {
  $self: &;

  &__button {
    display: inline-flex;
    align-items: center;
    background: transparent;
    color: $color-ace-black;
    font-family: $sans-roboto;
    font-size: rem(15px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-left: 0;
    padding-right:0;
    margin-right: rem(30px);
    .icon {
      margin-left: rem(8px);
      @include material-animation-default;
    }
    &[aria-expanded=true] {
      color: $color-ace-sky;
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &__panel {
    position: absolute;
    z-index: get-z-index('topics-filter-panel');
    left: 0;
    right: 0;
    transform: translateY(20px);
    background: $color-white;
    box-shadow: 0 rem(1px) rem(20px) 0 rgba(#000, 0.5);
    padding: rem(26px);
  }

  &__panel-grid {

    @include mq(tablet_sm) {
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(rem(300px), 1fr) );
      grid-auto-rows: 1fr;
      grid-column-gap: rem(50px);
      grid-row-gap:rem(50px);
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq(desktop_sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__panel-group {
    margin-bottom: rem(50px);
    @include mq(tablet_sm) {
      margin-bottom: 0;
    }
    &-label {
      display: block;
      margin: 0 0 rem(25px) 0;
      padding-bottom: rem(5px);
      font-family: $sans-roboto;
      font-size: rem(15px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: rem(-0.16px);
      color: $color-ace-black;
      border-bottom: solid rem(1px) $color-text-gray;
      text-transform: uppercase;
    }
  }

  &-group-options {
    font-family: $sans-mulish;
    font-size: rem(15px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: $color-ace-sky;


    &__button {
      display: flex;
      background: transparent;
      padding: 0;
      margin: rem(13px) 0 rem(14px) 0;

      &-icon {
        margin-right: rem(16px);
      }
      &-text {
        color: $color-ace-sky;
        text-transform: none;
      }
    }
  }

  &__panel-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: rem(26px);
    border-top: solid rem(1px) $color-ace-gray;
    position: relative;
    @include mq(tablet_sm) {

    }
  }

  &__reset-button {
    font-family: $sans-roboto;
    font-size: rem(13px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-text-gray;
    background: transparent;
    text-transform: uppercase;
    padding: rem(10px) rem(22px) rem(10px) 0;
    @include mq(tablet_sm) {
      position: absolute;
      left: 0;
      top: rem(31px);
    }
  }

  &__apply-button {
    padding: rem(10px) rem(22px);
    border-radius: rem(6px);
    background-color: $color-ace-sky;
    font-family: $sans-roboto;
    font-size: rem(18px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: $color-white;
    min-width: rem(100px);
    @include mq(tablet_sm) {
      margin-left: calc(50% - rem(50px));
    }
  }
}
