@use 'sass:color';
@use 'sass:map';
@use 'sass:math';
// ============================================================================
// Colors
// ==============================================================================

$color-text-gray: #7f8486;
$color-white: #fff;
$color-ace-blue: #00263d;
$color-ace-violet: #6179bd;
$color-ace-teal: #43beac;
$color-ace-red: #e93d42;
$color-ace-yellow: #f2b645;
$color-ace-gray: #dadfe1;
$color-ace-light-gray: #dadfe1;
$color-ace-purple: #6a5086;
$color-ace-sky: #5282de;
$color-ace-black: #1f2325;
$color-ace-sky-tinted: #3f6cc4;
$color-ace-purple-tinted: #573b75;
$color-ace-sky-reversed: #7c9fe3;
$color-callout-background: #f5a623;
$color-accent-fill: #4a90e2;
$color-error-fill: #d0021b;

:root {
  --color-text-gray: #7f8486;
  --color-white: #fff;
  --color-ace-blue: #00263d;
  --color-ace-violet: #6179bd;
  --color-ace-teal: #43beac;
  --color-ace-red: #e93d42;
  --color-ace-yellow: #f2b645;
  --color-ace-gray: #dadfe1;
  --color-ace-light-gray: #dadfe1;
  --color-ace-purple: #6a5086;
  --color-ace-sky: #5282de;
  --color-ace-black: #1f2325;
  --color-ace-sky-tinted: #3f6cc4;
  --color-ace-purple-tinted: #573b75;
  --color-ace-sky-reversed: #7c9fe3;
  --color-callout-background: #f5a623;
  --color-accent-fill: #4a90e2;
  --color-error-fill: #d0021b;
}
