@use '../../abstracts/' as *;
@use 'sass:math';

.article-resources {
  $self: &;
  margin-top: rem(24px);
  margin-bottom: rem(34px);

  // needed b/c specificity
  .resource-links {
    border-top: 1px solid $border__color;
    border-bottom: 1px solid $border__color;
    padding: 0;
    background-color: transparent;
    margin: 0;
    text-indent: none;
    list-style: none;
    border-radius: 0;
  }
}
