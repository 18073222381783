@use '/styles/abstracts' as *;
@use 'sass:math';
.card-related {
  $self: &;

  padding: rem(16px) rem(20px);
  display: flex;

  &__progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: rem(8px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-part {
      font-size: rem(13px);
      font-family: $sans-mulish;
      font-weight: $font__weight_regular;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-ace-black;

      &-title {
        color: $color-ace-purple;
        text-decoration: none;
      }

    }

    &-part + &-progress {
      margin-top: rem(7px);
    }

    &-progress {
      font-family: $sans-roboto;
      font-size: rem(13px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $color-ace-black;
      text-transform: uppercase;
    }
  }

}
