@use '/styles/abstracts' as *;

.login {
  $self: &;
  // background-color: yellow;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include padding-left(rem(20px));
  @include padding-right(rem(20px));
  margin-left: auto;
  margin-right: auto;
  transform: translateY(rem(-60px));
  @include mq(desktop_sm) {
    transform: translateY(rem(-80px));
  }


  &__user-avatar {
    width: rem(80px);
    height: rem(80px);
    border-radius: rem(40px);
  }


  &__headline {
    @include responsive-font-size(rem(48px));
    margin: rem(21.5px) auto rem(20px);
    font-family: $sans-mulish;
    font-weight: $font__weight_light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: rem(-0.86px);
    text-align: center;
    color: $color-ace-black;
    sup {
      display: inline-block;
      font-size: 0.5em;
      transform: translateY(-0.25em);
    }
  }

  &__message {
    @include responsive-font-size(rem(18px));
    font-family: $sans-mulish;
    font-weight: $font__weight_regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: $color-ace-black;
  }

  &__button {
    @include responsive-font-size(rem(18px));
    min-width: rem(123px);
    min-height: rem(52px);
    margin: rem(20px) auto;
    padding: rem(10px) rem(22px);
    border-radius: rem(6px);
    background-color: $color-ace-sky;
    font-family: $sans-roboto;
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: $color-white;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__help {
    width: 100%;
    max-width: rem(800px);
    margin: rem(21.5px) auto rem(20px);
    font-size: rem(15px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    a {
        color: $color-ace-sky;
    }
  }
}
