@use '../../abstracts/' as *;
@use 'sass:math';

.challenge-tracker {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  min-width: 100%;
  padding-left: rem(20px);
  padding-right: rem(20px);
  align-items: center;
  flex-wrap: wrap;
  margin-top: rem(30px);
  margin-bottom: rem(30px);
  @include mq(tablet_lg) {
    flex-wrap: nowrap;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__accepted,
  &__quit {
    width: 50%;
    height: rem(100px);
    border-top: 1px solid $color-ace-light-gray;
    @include mq(tablet_lg) {
      width: 100%;
      height: rem(80px);
      border-bottom: 1px solid $color-ace-light-gray;
      border-top: 1px solid $color-ace-light-gray;
    }
  }

  &__accepted {
    order: 1;
  }
  &__quit {
    order: 2;
    @include mq(tablet_lg) {
      order: 3;
    }
  }
  &__progress {
    order: 3;
    width: 100%;
    margin: rem(20px) auto;
    border-bottom: 1px solid $color-ace-light-gray;
    padding-bottom: rem(25px);
    @include mq(tablet_lg) {
      order: 2;
      width: initial;
      border: none;
      margin: initial;
      padding-bottom: 0;
    }
  }

  &__accepted {
    padding: rem(24px) 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-label {
      color: $color-ace-teal;
      display: flex;
      align-items: center;
      font-family: $sans-roboto;
      font-size: rem(18px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.48;
      letter-spacing: normal;
      text-transform: uppercase;
      .icon {
        margin-right: rem(8.5px);
      }
      @include mq(tablet_lg) {
        align-items: initial;
      }
    }
  }

  &__progress {
    display: flex;
    justify-content: center;
    min-width: 220px;
  }

  &__quit {
    display: flex;
    padding: rem(24px) 0;

    justify-content: flex-end;
    align-items: center;
    &-btn {
      font-family: $sans-roboto;
      font-size: rem(13px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: $color-white;
      border-radius: rem(6px);
      background-color: $color-text-gray;
      padding: rem(6.5px) rem(10px);
    }
  }
}
