@use '../abstracts/' as *;
@use 'sass:math';

.author {
  display: flex;
  font-size: rem(15px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  &__avatar {
    width: math.div(1, 3) * 100%;
    max-width: 80px;
    max-height: 80px;
    margin-right: rem(14px);
    flex: auto;
    img {
      border-radius: 80px;
    }
  }

  &__byline {
    width: math.div(2, 3) * 100%;
  }

  &__by {
    color: var(--color-text-gray);
    margin-bottom: rem(4px);
    display: block;
  }
  &__name {
    margin-bottom: rem(4px);
    display: block;

    a {
      text-decoration: none;
      color: var(--color-ace-sky);
    }
  }
}
