@use '../abstracts/' as *;
@use 'sass:math';

.profile {
  $self: &;
  padding: rem(60px) rem(20px) rem(80px) rem(20px);
  @include padding-top(rem(60px));
  @include padding-right(rem(20px));
  @include padding-bottom(rem(118px));
  @include padding-left(rem(20px));


  &__header {
    margin-bottom: rem(70px);
    @include mq(tablet_sm) {
      display: grid;
      grid-column-gap: rem(50px);
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq(desktop_sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__header-avatar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    @include margin-top(rem(40px));
    @include mq(tablet_sm) {
      margin-top: 0;
    }
  }

  &__header-avatar-details {
    @include responsive-font-size(rem(15px));
    font-weight: $font__weight_regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;

    a, button, span {
      display: block;
    }
  }

  &__header-avatar-image {
    position: relative;
    width: rem(100px);
    height: rem(100px);
    margin-right: rem(13px);
    border-radius: 100%;
    overflow: hidden;
  }

  &__header-avatar-link {
    color: $color-ace-sky;
    text-decoration: none;

    svg {
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  &__header-intro {
    h1 {
      @include responsive-font-size(rem(48px));
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
    }

    p {
      @include responsive-font-size(rem(15px));
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
    }

    @include mq(desktop_sm) {
      grid-column: span 2;
    }
  }

  &__topic {
    @include margin-bottom(rem(60px));


    &-label {
      display: block;
      margin: 0 0 rem(25px) 0;
      padding-bottom: rem(25px);
      @include responsive-font-size(rem(24px));
      font-weight: $font__weight_bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: rem(-0.25px);
      color: $color-ace-black;
      border-bottom: solid rem(1px) $color-text-gray;
    }
    @include mq(tablet_sm) {
      margin-bottom: 0;
    }

    &--email {

      .profile__topic-options__button {
        align-items: flex-start;
        &-text {
          display: flex;
          flex-direction: column;
          text-align: left;
          &-label {
            margin-bottom: rem(5px);
          }
          &-desc {
            display: block;
            line-height: 1.56;
          }
        }
      }
    }
  }

  &__topic-list {
    @include mq(tablet_sm) {
      display: grid;
      grid-column-gap: rem(50px);
      grid-row-gap: rem(80px);
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq(desktop_sm) {
      grid-row-gap: rem(100px);
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__topic-options {
    font-family: $sans-mulish;
    font-size: rem(18px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: $color-ace-black;


    &__button {
      display: flex;
      align-items: center;
      background: transparent;
      padding: 0;
      margin: rem(17px) 0 rem(17px) 0;



      &-icon {
        margin-right: rem(15px);
      }

      &-text {
        color: $color-ace-black;
        font-size: rem(18px);
        text-transform: none;
      }

      &-text-desc {
        display: none;
      }

      &:disabled {
        background: transparent;
        cursor: not-allowed;
      }
      &:disabled &-icon {
        opacity: 0.7;
        rect {
          fill: $color-text-gray;
        }
      }

      &:disabled &-text {
        color: $color-text-gray;
      }

    }
  }
}
