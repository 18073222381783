@use '../../abstracts/' as *;
@use 'sass:math';

.challenge {
  $self: &;

  .article {
    margin-bottom: 0;
  }

  &__masthead {
    // hide masthead mtn image
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: get-z-index('challenge-masthead');
    padding-top: calc(180 / 1440 * 100%);

    &-inner {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
     // show masthead mtn image
    @include mq(desktop_lg) {
      display: block;
      + .article-container {
        position: relative;
        @include mq(desktop_lg) {
        top: 4.25vw;
        margin-bottom: 4.25vw; // even out bottom edge overlap weirdness
        }
      }
    }
  }

  &__accept-row {
    min-height: rem(246px);
  }

  &__accept-btn {
    width: 100%;
    max-width: rem(540px);
    background-color: $color-ace-purple;
    border: rem(1px) solid $color-ace-purple;
    border-radius: rem(6px);
    padding: rem(23px) rem(48px);
    font-family: $sans-roboto;
    font-size: rem(18px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    margin-top: rem(30px);
    text-align: center;
    color: $color-white;
    @include hover-focus;
  }

  &__content {
    padding-top: to-percent(rem(155px), rem($site__max_width));
    margin-top: to-percent(rem(137px), rem($site__max_width)) * -1;
    // margin-bottom: math.div(strip-unit($site__max_width), 130) + vw;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-blend-mode: multiply;
    background-position: bottom center;
    background-position: top center;
    background-image: url('/assets/img/challenge-page-mtn-bg@2x.png');

    .hr-top-bottom-spacing:last-child {
      display: none;
    }
  }

  &__deeper-content {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
    background-color: #f6f7f7;
  }
}
