@use '../../abstracts/' as *;
@use 'sass:math';
.article-video {
  margin: 40px auto 0 auto;
  @include margin-top(rem(40px));
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
  width: 100%;
  max-width: $site__content_max_width;

  &__poster {
    position: relative;
    cursor: pointer;
    .icon {
      position: absolute;
      z-index: get-z-index('article-video-poster-icon');
      color: rgba($color-white, 1);
      width: 50px;
      height: 50px;
      top: calc(50% - rem(25px));
      left: calc(50% - rem(25px));
      cursor: pointer;

      &:hover {
        color: rgba($color-white, 0.75);
        @include material-animation-default(0.4s);
      }
      @include mq(tablet_sm) {
        width: 90px;
        height: 90px;
        top: calc(50% - 45px);
        left: calc(50% - 45px);
      }
      @include mq(desktop_sm) {
        width: 120px;
        height: 120px;
        top: calc(50% - 60px);
        left: calc(50% - 60px);
      }
    }
  }

  &__embed {
    position: relative;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
