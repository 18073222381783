@use '/styles/abstracts' as *;
@use 'sass:math';
.card-select {
  $self: &;

  font-family: $sans-mulish;
  @include responsive-font-size(rem(24px));
  border-radius: rem(48px);
  font-weight: $font__weight_light;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  text-transform: none;
  text-align: left;
  letter-spacing: -0.25px;
  color: $color-white;
  background-color: $color-ace-blue;
  display: flex;
  width: calc(100% - rem(40px));
  justify-content: space-between;
  align-items: center;
  padding: rem(20px) rem(20px) rem(20px) rem(30px);
  margin: rem(20px);

  &:focus {
    outline-offset: rem(3px);
  }


  &[aria-checked='false'] &__icon {
    color: $color-ace-blue;
  }

  &[aria-checked='true'] {
    background-color: $color-ace-teal;
  }
  &[aria-checked='true'] &__icon {
    color: $color-white;
  }

  &__icon,
  &__text {
    display: inline-flex;
  }
  &__icon {
    order: 2;
  }

  &__text {
    order: 1;
    padding-right: rem(10px);
  }
}
