@use '../../abstracts/' as *;
@use 'sass:math';
// Styles for the text area content of the article
.article-body {
  position: relative;
  max-width: rem(780px);

  // make way for the pin button
  // could be most anything
  // on mobile: any first child
  > *:first-child {
    max-width: calc(100% - rem(40px + 28.5 + 15px));
  }
  // on desktop: whatever comes right after tag group
  > *:nth-child(2) {
    @include mq(desktop_sm) {
      max-width: calc(100% - rem(40px + 28.5 + 15px));
    }
  }

  &__pin-button {
    position: absolute;
    right: rem(15px);
    top: 0;
  }

  &__sponsored {
    margin-bottom: rem(20px);
    @include mq(desktop_sm) {
      display: none;
    }
  }

  &-author-widget,
  &-topic-widget {
    margin-bottom: rem(20px);
    @include mq(desktop_sm) {
      @include sr-only;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $sans-mulish;
    @include responsive-font-size(rem(24px));
    font-weight: $font__weight_bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: rem(-0.25px);
    margin-bottom: rem(15px);
  }

  p,
  ul,
  ol,
  li {
    @include responsive-font-size(rem(18px));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: $color-ace-black;
  }

  p,
  li,
  ul,
  ol {
    @include margin-bottom(rem(30px));
  }

  p a {
    color: $color-ace-sky;
  }

  .block-img {
    @include margin-top(rem(60px));
    @include margin-bottom(rem(60px));
    margin-left: auto;
    margin-right: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }

  li {
    &:first-child {
      margin-top: rem(15px);
    }
    &:last-child {
      margin-bottom: rem(15px);
    }
  }

  ul,
  ol {
    @include padding-top(rem(30px));
    @include padding-right(rem(60px));
    @include padding-bottom(rem(30px));
    @include padding-left(rem(60px));
    background-color: rgba($color-ace-gray, 0.5);
    border-radius: rem(12px);
  }

  p,
  ul,
  ol,
  iframe {
    $body-heading-margin: rem(30px * 2);
    $headings: h1, h2, h3, h4, h5, h6;
    @each $heading in $headings {
      + #{$heading} {
        @include margin-top($body-heading-margin);
      }
    }
  }

  // because of images, embeds etc.
  // I think but can't really remeber...
  div {
    + p {
      margin-top: rem(15px);
    }
  }

  // <pre> as a blockquote
  pre {
    position: relative;
    @include responsive-font-size(rem(24px));
    @include padding-top(rem(85px));
    @include padding-right(rem(62px));
    @include padding-bottom(rem(60px));
    @include padding-left(rem(101px));
    @include margin-top(rem(70px));
    @include margin-bottom(rem(60px));
    border-top: rem(1px) solid $color-ace-light-gray;
    border-bottom: rem(1px) solid $color-ace-light-gray;
    display: block;
    white-space: normal;
    font-family: $sans-mulish;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--color-ace-black);
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      border-radius: 0;
    }
    &::before {
      top: 0;
      content: '“';
      text-indent: rem(10px);
      font-family: $sans-roboto;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $color-ace-black;
      height: rem(60px);
      @include responsive-font-size(rem(160px));
      @include padding-top(rem(35px));
      @include mq(tablet_sm) {
        text-indent: rem(26px);
        height: rem(85px);
      }
    }

    &::after {
    }
  }
}
