.loading {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  &--page {
    height: 100%;
    padding: rem(30px) rem(20px);
  }
}
