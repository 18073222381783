@use '/styles/abstracts' as *;
@use 'sass:math';

.my {
  $self: &;
  color: $color-ace-black;

  &--theme-dark {
    background-color: $color-ace-purple-tinted;
    color: $color-white;
  }

  &__wrap {
    @include padding-top(90px);
    @include padding-bottom(120px);
  }

  &__header {
    width: 100%;
    display: flex;
    padding-left: rem(20px);
    padding-right: rem(20px);
    @include margin-bottom(70px);
    &-branding {
      display: none;
      flex-direction: column;
      justify-content: flex-end;
      @include margin-right(26px);
      @include mq(tablet_sm) {
        display: flex;
      }
    }
    &-content {
      width: 100%;
      h2 {
        @include responsive-font-size(rem(48px));
        @include margin-bottom(15px);
        font-weight: $font__weight_light;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: rem(-0.86px);
      }

      &-headline {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        a {
          color: $color-ace-sky;
          @include margin-bottom(15px);
        }
      }


      p {
        @include responsive-font-size(rem(15px));
        max-width: rem(676px);
        margin: 0;
        font-weight: $font__weight_regular;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
      }
    }
  }

  &__content-header {
    margin-bottom: rem(20px);
    padding-left: rem(20px);
    padding-right: rem(20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__duration {
    font-family: $sans-roboto;
    @include responsive-font-size(rem(15px));
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-ace-black;
    text-transform: uppercase;
  }

  &__topics {
    padding-left: rem(20px);
    padding-right: rem(20px);

    &--dark {
      .topics-list__topic-item-label,
      .topics-list__topic-list-item {
        color: $color-white;
      }
    }
  }
}
