@use "styles/abstracts" as *;

input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=number],
input[type=tel],
input[type=range],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=color],
textarea {
  font-family: inherit;
  border-style: solid;
  border-width: thin;
}

input:-webkit-autofill {
  background-color: inherit !important;
  color: inherit !important;
}

input[type=text],
input[type=email],
input[type=url],
input[type=search] {
  text-transform: none;
}

input[type=text],
input[type=email],
input[type=url],
input[type=search],
input[type=password] {
  &::placeholder {
    text-transform: none;
  }
}

// textarea {}
button,
input[type=button],
input[type=reset],
input[type=submit] {
  border: none;
  box-shadow: none;
  width: auto;
  padding: $button__padding;
  background-color: $button__background-color;
  color: $button__color-text;
  text-transform: $button__text_transform;
  font-size: $button__font_size;
  font-weight: $button__font_weight;
  line-height: $button__line_height;

  &:disabled {
    color: $button__color_text_disabled;
    background-color: $button__background_color_disabled;
  }
}

textarea {
  width: 100%;
}

input,
textarea,
button,
select {
  padding: 0;
  margin: 0;
  user-select: text;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  width: auto;
  padding: $button__padding;
  font: normal normal $button__font-weight #{$button__font-size}/#{$button__line-height} $button__font-family;
  background-color: $button__background-color;
  color: $button__color-text;
}

button,
input,
textarea {
  appearance: none;
}

input[type=image] {
  padding-left: 0;
  padding-right: 0;
}

fieldset {
  border: 1px solid $border__color;

  ul {
    list-style: none;
  }
}

legend {
  border: 0;
  padding: 0;
}

button,
input[type=submit] {
  cursor: pointer;
}


// ================ Input element overrides ================

input[type=checkbox],
input[type=radio] {
  display: inline;
  margin: 0 8px 0 0;
  padding: 0;
  width: auto;
}

input[type=checkbox] {
  appearance: checkbox;
}

input[type=radio] {
  appearance: radio;
}

