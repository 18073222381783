@use '../abstracts/' as *;

.advertisement {
  @include padding-right(rem(20px));
  @include padding-left(rem(20px));
  @include margin-bottom(rem(60px));
  @include margin-top(rem(60px));

  a {
    display: block;
    max-width: 1220px;
    margin: auto;
  }


  @at-root {
    .my-compass + &,
    .explore__content--no-results + &,
    .article + & {
      margin-top: 0;
    }

    .article + .my + & {
      @include margin-top(rem(60px));
    }
  }

}
