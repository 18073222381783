@use '../abstracts/' as *;

.arrow-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $sans-roboto;
    font-size: rem(15px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background: transparent;
    .icon {
      margin-left: rem(5px);
      transform: rotate(180deg);
    }
}

button.arrow-link {
  padding: 0;
}
