@use '/styles/abstracts' as *;
@use 'sass:math';
.home-start-feature {
  $self: &;

  max-width: rem(520px);
  text-align: left;

  &__details {
    max-width: rem(668px);
    margin: 0 auto;

    p {
      color: $color-ace-black;
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
    }

    &-title {
      margin: 0 auto rem(10px);
      color: $color-ace-black;
      font-weight: $font__weight_bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: rem(-0.25px);
      font-size: rem(24px);
      text-align: left;
    }
  }

  &__icon {
    display: block;
    margin: 0 0 rem(10px);
    color: $color-ace-sky;
  }
}
