@use '/styles/abstracts' as *;
@use 'sass:math';
.card-slider {
  $self: &;
  // padding-left: rem(10px);
  // padding-right: rem(10px);
  width: calc(100% - rem(20px));
  margin-left: auto;
  margin-right: auto;
  // overflow: hidden;
  @include mq(desktop_md) {
    // unslick styles
    width: 100%;
    padding-left: rem(20px);
    padding-right: rem(20px);
  }

  .slick-list {
    // overflow: visible;
    @include mq(desktop_md) {
      // unslick styles
      height: auto !important;
      overflow: visible;
    }
  }

  .slick-track {
    display: flex;
    align-items: flex-start;
    @include mq(desktop_md) {
      // unslick styles
      display: grid;
      width: 100% !important;
      grid-auto-rows: 1fr;
      grid-column-gap: rem(20px);
      grid-row-gap: rem(10px);
      grid-template-columns: repeat(3, 1fr);
      &::before,
      &::after {
        display: none;
      }
    }
  }
  .slick-slide {
    padding-left: rem(10px);
    padding-right: rem(10px);
    padding-bottom: rem(10px);
    @include mq(desktop_md) {
      // unslick styles
      float: none;
      height: auto !important;
      width: auto !important;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .slick-dots {
    bottom: rem(-45px);
    @include mq(desktop_md) {
      // just finicky styles
      bottom: rem(-25px) + rem(-30px);
    }
    li {
      width: auto;
      height: auto;
      margin: 0 rem(3px);
    }

    button {
      display: inline-flex;
      width: rem(8px);
      height: rem(8px);
      padding: 0;
      border-radius: rem(4.5px);
      &::before {
        content: '';
        display: none;
      }
      span {
        @include sr-only;
      }
    }
    .slick-active button {
      width: rem(40px);
    }
  }

  // Theme: .card-slider--light (DEFAULT)
  &--light {
    .slick-dots button {
      background-color: $color-ace-teal;
    }
  }

  // Theme: .card-slider--light
  &--dark {
    .slick-dots button {
      background-color: $color-white;
    }
  }
}
