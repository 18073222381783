@use '/styles/abstracts' as *;
@use 'sass:math';
.trail-segment {
  $self: &;
  width: 130px;
  height: 195px;
  @include mq(desktop_sm) {
    width: initial;
    height: initial;
  }

  &__path {
    fill: none;
    stroke: #1F2325;
    stroke-dasharray: 4,12;
    stroke-width: 3;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
  
  &__mask {
    fill: none;
    stroke: white;
    stroke-width: 3;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }

  &--animated {
    #{$self}__mask {
      animation: dashReveal 2.5s linear 1 forwards;
    }
  }
}

@keyframes dashReveal {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
