@use '/styles/abstracts' as *;

.toast {
  $self: &;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: get-z-index('toast');
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  min-width: to-percent(880px, $site__max_width);
  max-width: rem(880px);
  min-height: rem(40px);
  margin-left: auto;
  margin-right: auto;
  padding: 0 rem(20px);
  font-family: $sans-mulish;
  font-size: rem(18px);
  font-weight: $font__weight_regular;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  background-color: transparent;
  color: $color-white;
  text-align: center;

  &__inner {
    width: 100%;
    height: 100%;
    padding: rem(20px) rem(30px);
    background-color: $color-ace-blue;
    a {
      color: inherit;
    }
  }

}
