@use '../../abstracts/' as *;
@use 'sass:math';

body.data-tour-user-menu-open {
  overflow-y: hidden !important;
  .layout__page-header {
    position: fixed;
    top: 0;
    @include mq(desktop_sm) {
      position: relative;
      top: unset;
    }
  }
}

[data-tour='tour-fake-user-menu'] {
  z-index: 10;
  height: 100%;
  @include mq(desktop_sm) {
    position: absolute;
    top: rem(5px);
    right: rem(20px);
    bottom: unset;
    z-index: 10;
    height: unset;
  }
  .nav__user-menu {
    &--open {
      transition-timing-function: unset;
    }
  }
}
