@use '/styles/abstracts' as *;

body.user-menu-open {
  overflow-y: hidden !important;
  .nav__user-menu {
    pointer-events: auto;
  }
  .nav__user {
    z-index: get-z-index('nav-user-menu-open-mobile');
  }
  @include mq(desktop_sm) {
    overflow-y: auto;
    .nav__user {
      z-index: get-z-index('nav-user-menu-open-desktop');
    }
  }
}

.nav {
  $self: &;
  height: $site__header_height_mobile;
  @include mq(desktop_sm) {
    height: $site__header_height_desktop;
  }

  &__inner {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    padding: 0 rem($gutter__mobile);
    justify-content: space-between;
    align-items: center;
    @include mq(desktop_sm) {
      &__inner {
        padding: 0 rem($gutter__desktop);
      }
    }
  }

  &__branding {
    flex-basis: 20%;
    padding-top: rem(20px);
    padding-bottom: rem(16px);
    @include mq(desktop_sm) {
      flex-basis: 25%;
    }

    &-logo-desktop {
      display: none;
    }
    @include mq(desktop_sm) {
      &-logo-mobile {
        display: none;
      }
      &-logo-desktop {
        display: inline-flex;
      }
    }
  }

  &__items {
    flex-basis: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    @include mq(desktop_sm) {
      flex-basis: 50%;
    }
    &.west {
      #{$self}__item--mycompass {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: auto;
          right: auto;
          bottom: 0px;
          left: 50%;
          content: "";
          transform: translateX(-50%);
          border-right: rem(13px) solid transparent;
          border-bottom: rem(13px) solid #e53d46;
          border-left: rem(13px) solid transparent;
          @include mq(desktop_sm) {
            display: none;
          }
        }
      }
    }
    &.east {
      #{$self}__item--explore {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: auto;
          right: auto;
          bottom: 0px;
          left: 50%;
          content: "";
          transform: translateX(-50%);
          border-right: rem(13px) solid transparent;
          border-bottom: rem(13px) solid #e53d46;
          border-left: rem(13px) solid transparent;
          @include mq(desktop_sm) {
            display: none;
          }
        }
      }
    }
    &--needle {
      &.west #{$self}__item-needle {
        @include mq(desktop_sm) {
          visibility: visible;
          transform: translateY(43px) rotate(-40deg);
          z-index: get-z-index('nav-item-needle-active');
        }

      }

      &.east #{$self}__item-needle {
        @include mq(desktop_sm) {
          visibility: visible;
          z-index: get-z-index('nav-item-needle-active');
          transform: translateY(43px) rotate(40deg);
        }
      }

      &.unknown #{$self}__item-needle {
        z-index: get-z-index('nav-item-needle-inactive');
        visibility: hidden;
      }
    }
  }

  &__item {
    position: relative;
    font-family: $sans-roboto;
    font-size: rem(13px);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $color-ace-blue;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @include mq(desktop_sm) {
      font-size: rem(18px);
    }
    &-needle {
      visibility: hidden;
      @include sr-only();
      z-index: get-z-index('nav-item-needle-inactive');
      position: relative;
      width: rem(14px);
      height: rem(62px);
      transform-origin: center;
      transform: translateY(43px);
      @include material-animation-default(0.8s);
      transition-property: all;
    }

    &:first-child {
      padding-right: 0;
      @include mq(desktop_sm) {
        padding-left: rem(10px);
        padding-right: rem(10px);
      }
    }
    &:last-child {
      padding-left: 0;
      @include mq(desktop_sm) {
        padding-left: rem(10px);
        padding-right: rem(10px);
      }
    }
  }

  &__user {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: get-z-index('nav-user-menu-closed-mobile');
    @include mq(desktop_sm) {
      z-index: get-z-index('nav-user-menu-closed-desktop');
    }
    &--logged-in {
      flex-basis: 20%;
    }
    @include mq(desktop_sm) {
      flex-basis: 25%;
    }
    a {
      color: var(--color-ace-black);
      font-family: $sans-mulish;
      font-size: rem(18px);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-decoration: none;
      @include mq(desktop_sm) {
        font-size: rem(15px);
      }
    }

    &-menu-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-menu-button {
      background: transparent;
      padding: 0;

      &__mobile {
        display: flex;
        flex-direction: column;
        height: rem(40px);
        width: auto;
        justify-content: center;
        align-items: flex-end;
        color: $color-ace-black;
        @include mq(desktop_sm) {
          @include sr-only;
        }
      }

      &[aria-expanded='false'] &__mobile {
        .icon-menu {
          display: block;
        }
        .icon-close {
          display: none;
        }
      }
      &[aria-expanded='true'] &__mobile {
        .icon-menu {
          display: none;
        }
        .icon-close {
          display: block;
        }
      }

      &__desktop {
        display: flex;
        align-items: center;
        background-color: var(--color-white);
        color: var(--color-ace-black);
        font-family: $sans-mulish;
        font-size: rem(15px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        text-transform: none;
        @include sr-only;
        @include mq(desktop_sm) {
          @include sr-only-reset;
        }
      }
    }

    &-name {
      padding-right: rem(11px);
    }

    &-avatar {
      border-radius: rem(20px);
    }
  }

  &__user-menu {
    position: fixed;
    top: $site__header_height_mobile;
    right: 0;
    transform: translateX(100vw);
    pointer-events: none;
    opacity: 0;
    width: 100vw;
    background-color: $color-ace-teal;
    height: calc(100vh - $site__header_height_mobile);
    padding: rem(20px);
    // slide out
    @include material-animation-fast-out-slow-in(350ms);

    @include mq(desktop_sm) {
      position: absolute;
      top: rem(64px);
      right: 0;
      width: initial;
      min-width: rem(260px);
      height: initial;
      padding: rem(12px) rem(14px) 0 rem(20px);
      border-radius: rem(6px);
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
      background-color: var(--color-white);
      opacity: 0;
      transform: translateY(-5px);
      transition: opacity 0.2s ease, transform 0.2s ease;
    }

    &--open {
      transform: translateX(0);
      opacity: 1;
      // slide in
      @include material-animation-fast-out-slow-in(200ms);
      @include mq(desktop_sm) {
        display: flex;
        flex-direction: column;
        min-height: rem(171px);
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    &-item, button#{&}-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(25px);
      background: inherit;
      padding-left: 0;
      text-transform: none;
      @include mq(desktop_sm) {
        margin-bottom: rem(10px);
      }

      &--focus {
        @include focus-ring($element-state: false);

        &:hover {
          outline: none;
        }
      }

      &-label {
        color: var(--color-white);
        @include mq(desktop_sm) {
          color: var(--color-ace-black);
        }
      }

      &-count,
      &-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: rem(36px);
        height: (36px);
        @include mq(desktop_sm) {
          width: rem(27px);
          height: rem(27px);
        }
      }

      &-count {
        border-radius: 100%;
        background-color: var(--color-white);
        color: var(--color-ace-blue);
        font-size: rem(15px);
        @include mq(desktop_sm) {
          background-color: var(--color-ace-sky);
          color: var(--color-white);
        }
      }

      &-icon {
        color: var(--color-ace-blue);
      }
    }
  }
}
