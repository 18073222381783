@use '../abstracts/' as *;
@use 'sass:math';

.show-more{
  $self: &;
  margin: rem(80px) auto;
  text-align: center;


  &__button {
    min-width: rem(150px);
    min-height: rem(52px);
    padding: rem(10px) rem(22px);
    border-radius: rem(6px);
    background-color: $color-ace-sky;
    font-size: rem(18px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: $color-white;
  }
}
