// Mountain Background
.bg-mtn {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-blend-mode: multiply;
  background-position: bottom center;
  &--valign-top {
    background-position: top center;
    background-image: url('/assets/img/challenge-page-mtn-bg@2x.png');
  }

  &--valign-middle {
    background-position: center center;
    background-image: url('/assets/img/challenge-page-mtn-bg@2x.png');
  }

  &--valign-bottom {
    background-position: bottom center;
    background-image: url('/assets/img/challenge-page-mtn-bg-no-fade@2x.png');
  }

  &--purple-tinted-valign-bottom {
    background-position: bottom center;
    background-image: url('/assets/img/challenge-page-mtn-bg-no-fade-purple-tinted@2x.png');
  }
}
