@use '/styles/abstracts' as *;
@use 'sass:math';
.card {
  $self: &;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--color-white);
  min-height: rem(600px);
  max-height: rem(600px);

  a {
    text-decoration: none;
  }
}
