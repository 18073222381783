@use '/styles/abstracts' as *;

// ==========================================================================
// Layout Row
//
// Acts as a consistent generic wrapper/divider for all site content rows.
// Requires one inner div to create a centered max width area to hous
// the content for that row.
//
// !!BEFORE ADDING TO THIS CLASS!!
// 1. Try to think of a different approach to your use case
// 2. Add a subclass modifier to the layout-row element as the parent class
//    of your module/component.
//
// @example HANDLEBARS (wrapper partial)
// {{#> layout-row row-name="my-component-row"}}
//  <div class="my-component"></div>
// {{/layout-row}}
//
// @output HTML
//  <div class="layout-row my-component-row">
//    <div class="layout-row__inner my-component-row__inner">
//      <div class="my-component"></div>
//    </div>
//  </div>
// ==========================================================================
.layout-row {
  $self: &;
  @include layout-row();
  // @include debug-box-model($color__gold, false);

  &__inner {
    @include layout-row-inner();
    // @include debug-box-model(lighten($color__gold, 20%));
  }

  // use when there is only one row in the layout
  // such as a content page like the general info pages
  // @example HTML
  // <section class="layout-row layout-row-page">
  //  <div class="layout-row__inner">
  &-page {
    padding-top: $gutter__desktop;
    padding-bottom: $spacing__hr_desktop * 2 + $hr__border_width;
  }


  &--ace-blue {
    background-color: $color-ace-blue;
  }

  &--ace-purple-tinted {
    background-color: $color-ace-purple-tinted;
  }
}
