@use '../../abstracts/' as *;
@use 'sass:math';

.takeover {
  $self: &;
  position: fixed;
  overflow-y: auto;
  z-index: get-z-index('takeover');
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  &__shell {
    &-inner {
      display: flex;
      width: 100%;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
    }

    &-container {
      z-index: get-z-index('takeover-shell-container');
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: relative;
      background-color: rgba($color-white, 1);
    }

    &-header {
      z-index: get-z-index('takeover-shell-header');
      position: fixed;
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: rgba($color-white, 1);
      left: 0;
      right: 0;
      top: 0;
      padding: rem(20px);
      height: rem(80px);
    }

    &-body {
      z-index: get-z-index('takeover-shell-body');
      display: flex;
      flex: auto;
      width: 100%;
      padding-top: rem(80px);
      background-color: rgba($color-white, 1);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-blend-mode: multiply;
      background-position: bottom center;
      background-image: url('/assets/img/challenge-page-mtn-bg-no-fade@2x.png');
    }

    &-footer {
      z-index: get-z-index('takeover-shell-footer');
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-ace-blue;
      display: flex;
      justify-content: flex-end;
      height: rem(80px);
      &--space-between {
        justify-content: space-between;
      }
    }
  }

  &__header-section {
    display: flex;
    flex-basis: to-percent(1, 3);
    &--branding {
      align-items: center;
      justify-content: flex-start;
    }
    &--indicate {
      align-items: center;
      justify-content: center;
    }
    &--close {
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  &__logo-desktop {
    display: none;
  }
  @include mq(desktop_sm) {
    &__logo-mobile {
      display: none;
    }
    &__logo-desktop {
      display: inline-flex;
    }
  }

  &__indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__x-btn {
    width: rem(24px);
    height: rem(24px);
    background-color: transparent;
    padding: 0;
    color: $color-ace-black;
  }

  &__button-prev,
  &__button-next,
  &__button-finish {
    font-family: $sans-mulish;
    @include responsive-font-size(rem(18px));
    font-weight: $font__weight_light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: rem(-0.25px);
    color: $color-white;
    text-transform: none;
    display: inline-flex;
    align-items: center;
    padding-left: rem(30px);
    padding-right: rem(30px);
  }

  &__button-next,
  &__button-finish {
    background-color: $color-ace-sky;
  }

  &__button-prev {
    background-color: transparent;
    .icon {
      margin-right: rem(5px);
    }
  }
  &__button-next {
    .icon {
      transform: rotate(-180deg);
      margin-left: rem(5px);
    }
  }
  &__button-finish {
  }
}
