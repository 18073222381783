@use '../abstracts/' as *;
@use 'sass:math';

.feedback {
  $self: &;
  padding: 0 rem(20px);
  @include layout-row();
  @include margin-top(rem(128px));

  &__inner {
    @include layout-row-inner();
    border-radius: rem(12px);
    background-color: rgba(218, 223, 225, 0.5);
    overflow: hidden;
  }

  &__header {
    background-image: linear-gradient(to right, $color-ace-sky 15%, $color-ace-teal);
    color: $color-white;
    text-align: center;
    padding: rem(14px) rem(20px);
    &-title {
      color: inherit;
      @include responsive-font-size(rem(24px));
      font-weight: $font__weight_bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: rem(-0.25px);
      text-align: center;
      margin: 0;
    }
  }

  &__body {
    text-align: center;
    padding-bottom: rem(25px);

    &-label {
      margin: rem(15px) auto;
      @include responsive-font-size(rem(15px));
    }

    &-actions {

    }
  }


}
