@use '/styles/abstracts' as *;
@use 'sass:math';

.notify {
  width: 100%;
  min-height: rem(50px);
  color: $color-white;
  z-index: get-z-index('notify');
  background-image: linear-gradient(
    90deg,
    $color-ace-sky 15%,
    $color-ace-teal 85%
  );

  @include mq(desktop_sm) {
    min-height: rem(80px);
  }

  &--back {
    background-color: $color-ace-purple;
    background-image: none;
    position: sticky;
    top: 0;
    @include padding-top(14px);
    @include padding-bottom(14px);
    @include padding-left(20px);
    @include padding-right(20px);
    span,
    a {
      color: inherit;
    }
  }

  // Back to Challenge
  &__back-link {
    display: inline-flex;
    text-decoration: none;
    &-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 80px;
      svg {
        margin-right: rem(14px);
      }
      @include mq(tablet_sm) {
        max-width: unset;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .label {
        font-size: rem(13px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

      .title {
        font-size: rem(16px);
        font-weight: $font__weight_light;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: rem(-0.19px);
        @include mq(tablet_sm) {
          font-size: rem(18px);
          line-height: 1.78;
        }
      }
    }
  }

  &--basic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include padding-top(20px);
    @include padding-bottom(20px);
    @include padding-left(20px);
    @include padding-right(20px);
    @include mq(tablet_sm) {
    }
  }
  &__left,
  &__middle,
  &__right {
  }

  &__left,
  &__right {
    @include mq(tablet_sm) {
      width: math.div(107, 1440) * 100%;
    }
  }

  &__middle {
    width: 100% - ((math.div(107, 1440) * 100%) * 2);
    text-align: center;
    @include padding-left(rem(4px));
    @include padding-right(rem(4px));
    p {
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: rem(676px);
      + a,
      + p {
        margin-top: rem(10px);
      }
    }

    .notify-split {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      a {
        color: $color-white;
        font-size: rem(16px);
        @include mq(tablet_sm) {
          font-size: rem(18px);
        }
      }

      > div:nth-child(1),
      > div:nth-child(2) {
        @include padding-left(rem(10px));
        @include padding-right(rem(10px));
        @include margin-top(rem(5px));
        @include margin-bottom(rem(5px));
      }
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
  }

  &-dismiss-btn {
    width: rem(18px);
    height: rem(18px);
    background-color: transparent;
    padding: 0;

    .icon {
      width: rem(18px);
      height: rem(18px);
    }
    @include mq(tablet_md) {
      width: rem(24px);
      height: rem(24px);
      .icon {
        width: rem(24px);
        height: rem(24px);
      }
    }
  }

  &-button {
    font-family: $sans-roboto;
    display: inline-flex;
    padding: rem(10px) rem(22px);
    @include padding-top(10px);
    @include padding-bottom(10px);
    @include padding-left(22px);
    @include padding-right(22px);
    border-radius: rem(6px);
    background-color: $color-white;
    box-shadow: rem(-4px) rem(4px) 0 0 $color-ace-blue;
    color: $color-ace-blue;
    font-family: $sans-roboto;
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-decoration: none;
    text-transform: uppercase;
  }
}
