@use '../../abstracts/' as *;
@use 'sass:math';

.article {
  $self: &;
  @include margin-bottom(rem(80px));
  &-container {
    background-color: $color-white;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    @include mq($site__content_max_width) {
      max-width: $site__content_max_width;
    }

    &--rounded {
      border-radius: rem(6px) rem(6px) 0 0;
    }
  }

  &--pullup &-container {
    border-radius: rem(6px) rem(6px) 0 0;
    transform: translateY(rem(-60px));
    margin-bottom: rem(-60px);
    @include mq(tablet_lg) {
      max-width: calc(100% - 40px);
      transform: translateY(rem(-139px));
      margin-bottom: rem(-139px);
    }
    @include mq($site__content_max_width) {
      max-width: $site__content_max_width;
    }
  }

  &-main,
  &-footer {
    display: flex;
    flex-direction: column;
    padding-right: rem(20px);
    padding-left: rem(20px);
    @include mq(desktop_sm) {
      flex-direction: row;
      padding-right: rem(30px);
      padding-left: rem(30px);
    }

    + hr {
      margin-top: rem(20px);

      @include mq(desktop_sm) {
        $hrAdjustedForPadding: rem(30px);
        width: #{calc(100% - $hrAdjustedForPadding)};
      }
    }
  }

  &-rail {
    display: none;
    padding-top: 0;
    @include padding-right(rem(40px));
    @include padding-left(rem(40px));
    @include mq(desktop_sm) {
      display: block;
      @include padding-top(rem(40px));
      padding-left: 0;
      width: math.div(340, 1220) * 100%;
    }

    &__sponsored {
      margin-bottom: rem(20px);
    }

    .rail-widget + .rail-widget:not(.article-completion)::before {
      display: block;
      margin-top: rem(30px);
      margin-bottom: rem(30px);
      content: '';
      width: 100%;
      height: 0;
      border-top: solid 1px var(--color-ace-gray);
    }

    .rail-widget {
      display: none;
      @include mq(desktop_sm) {
        display: block;
        &.author {
          display: flex;
        }
      }
    }
  }

  &-contents {
    position: relative;
    @include padding-top(rem(40px));

    @include mq(desktop_sm) {
      @include padding-left(rem(30px));
      width: 100 - math.div(340, 1220) * 100%;
    }
  }
}
