@use '../abstracts/' as *;
@use 'sass:math';

.sentiment {
  $self: &;
  display: flex;
  justify-content: center;
  align-items: center;


  &__button {
    font-family: $emoji;
    width: rem(90px);
    height: rem(90px);
    border-radius: 100%;
    margin: 0 rem(10px);
    background-color: $color-white;
    border: rem(5px) solid $color-ace-gray;

    &[aria-checked='true'] {
      border-color: $color-ace-teal;
    }

    &-text {
      @include responsive-font-size(rem(42px));
    }
  }

  &-card-panel {
    display: flex;
    flex-direction: column;
    text-align: center;
    @include padding-bottom(40px);

    &__content {
      max-width: rem(626px);
      margin-left: auto;
      margin-right: auto;
      @include mq(desktop_sm) {
        @include padding-top(90px);
      }

      h2 {
        @include responsive-font-size(rem(48px));
        font-weight: $font__weight_light;
        line-height: 1.29;
        letter-spacing: rem(-0.86px);
        text-align: center;
        color: $color-ace-black;
      }
    }

  }
}
