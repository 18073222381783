@use '/styles/abstracts/' as *;

// ==========================================================================
// Top Level page containers (mostly)
//
// Implemented flexbox sticky footer styles here per this reduced case on codepen.
// Pen: https://codepen.io/j-cam/pen/4818f2dea635a5e0fe31623bd1e06ec6/
// Forked from: https://codepen.io/chriscoyier/pen/RRbKrL
// Article: https://css-tricks.com/couple-takes-sticky-footer/
// ==========================================================================
$module: 'layout';

.layout {
  $self: &;

  &__body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: 100vh; // Avoid the IE 10-11 `min-height` bug.
    overflow-x: hidden;
  }

  #__next,
  &__app {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    min-height: 100vh; // Avoid the IE 10-11 `min-height` bug.
  }

  &__page {
    flex: 1 0 auto; // Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size.

    &-header {
      width: 100%;
      height: $site__header_height_mobile;
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      background-color: $color-white;
      z-index: get-z-index('layout-page-header');
      @include mq(desktop_sm) {
        height: $site__header_height_desktop;
      }
      &::after {
        // shadow under nav
        content: '';
        z-index: get-z-index('layout-page-header-shadow');
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: rem(30px);
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.04), rgba(255, 255, 255, 0));
        pointer-events: none;
      }
    }

    &-main {
      z-index: get-z-index('layout-page-main');
      position: relative;
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }

    &-footer {
      width: 100%;
      flex-shrink: 0; // Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size.
    }
  }
}
