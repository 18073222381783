@use '/styles/abstracts' as *;

.site-footer {
  $self: &;
  display: flex;
  flex-direction: column;
  background-color: var(--color-ace-blue);
  color: $color-white;
  @include mq(tablet_md) {
    flex-direction: row;
  }

  p {
    color: $color-white;
  }
  a {
    text-decoration: none;
    color: inherit;
  }

  &__branding {
    display: flex;
    background-color: var(--color-ace-red);
    align-items: center;
    padding: rem(17.5px);
    color: $color-white;
    @include mq(tablet_md) {
      flex-basis: 25%;
      justify-content: center;
      max-width: rem(180px);
      padding: rem(30px);
      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  &__content {
    order: 2;
    color: $color-white;
    padding: rem(20px);
    @include mq(tablet_md) {
      order: 1;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
    }
    &-statement {
      @include sr-only;
      p {
        max-width: rem(665px);
        font-size: rem(15px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        color: var(--color-white);
      }
      @include mq(tablet_md) {
        @include sr-only-reset;
      }
    }

    &-legal {
      p {
        font-size: rem(12px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--color-white);
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__contact {
    order: 1;
    flex: 1;
    justify-self: flex-end;
    color: $color-white;
    padding: rem(20px) rem(30px) rem(20px) rem(20px);
    @include mq(tablet_md) {
      max-width: rem(211px);
      order: 2;
    }
    a {
      color: inherit;
    }
  }

  &__contact-social {
    display: flex;
    align-items: center;
    margin-bottom: rem(72px);
    @include mq(tablet_md) {
      margin-bottom: rem(24px);
      justify-content: space-between;
    }
    a {
      margin-right: rem(15px);
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__contact-info {
    font-size: rem(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-white);
    .email-phone,
    .address {
      display: block;
    }

    .email-phone {
      margin-bottom: rem(15px);
      .email:after {
        display: inline-block;
        content: '|';
        padding-left: 0.75em;
        padding-right: 0.75em;
        @include mq(tablet_md) {
          display: none;
        }
      }
      .phone {
        @include mq(tablet_md) {
          margin-top: rem(15px);
        }
      }
    }

    .address {
      .addr-1:after {
        display: inline-block;
        content: ',';
        padding-right: 0.4em;
        @include mq(tablet_md) {
          display: none;
        }
      }
    }

    @include mq(tablet_md) {
      display: flex;
      flex-direction: column;

      .email-phone,
      .address {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @include mq(mobile_xs) {
  }
  @include mq(mobile_sm) {
  }
  @include mq(mobile_md) {
  }
  @include mq(mobile_lg) {
  }
  @include mq(tablet_sm) {
  }
  @include mq(tablet_md) {
  }
  @include mq(tablet_lg) {
  }
  @include mq(tablet_md) {
  }
  @include mq(desktop_md) {
  }
  @include mq(desktop_lg) {
  }
  @include mq(desktop_xl) {
  }
}
