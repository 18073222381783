@use '/styles/abstracts' as *;
@use 'sass:math';

.progress-ring {
  .progress-arc {
    transition: stroke-dashoffset 3s;
    stroke-width: 6;
    stroke: $color-ace-teal;
  }

  &__circle-group {
    transform-origin: center;
    transform: rotate(-90deg) translate(13px, 13px);

    &-track {
      fill: $color-white;
      stroke: $color-ace-light-gray;
      stroke-width: 6;
      stroke-opacity: 0.5;
    }

    &-progress {
      stroke: $color-ace-teal;
      stroke-width: 6;
      stroke-opacity: 1;
    }
  }

  &__indicator-dot-rotator-box {
    transform-origin: center;
  }

  &__counter-count {
    font-family: $sans-mulish;
    font-weight: $font__weight_regular;
  }
  &__counter-label {
    font-family: $sans-roboto;
    font-weight: $font__weight_regular;
  }
}
