@use '/styles/abstracts' as *;
@use 'sass:math';

.my-challenges {
  $self: &;
  background-color: $color-ace-purple-tinted;
  color: $color-white;
  &__wrap {
    @include padding-top(90px);
    @include padding-bottom(120px);
  }

  &__tablist {
    margin-bottom: rem(20px);
    padding-left: rem(20px);
    padding-right: rem(20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &-button {
      font-family: $sans-roboto;
      font-size: rem(15px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      color: $color-white;
      background-color: $color-ace-purple;
      padding: rem(10px) rem(20px);
      border-radius: rem(16px);
      margin-right: rem(6px);
      margin-bottom: rem(6px);
      &[aria-selected='true'] {
        color: $color-ace-purple;
        background-color: $color-white;
      }
    }

    &-link {
      a {
        color: $color-white;
      }
    }

  }

}
