@use '../abstracts/' as *;
@use 'sass:math';

.filters {
  $self: &;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: rem(40px) 0;
  flex-wrap: wrap;

  @include mq(tablet_lg) {
  }

  &__info {
    font-family: $sans-mulish;
    font-size: rem(15px);
    line-height: normal;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $color-ace-black;
    text-transform: none;
    padding-right: rem(20px);
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
  }
}
