@use '../abstracts/' as *;

.tag {
  border-radius: 12px;
  text-decoration: none;

  &--new,
  &--sponsored {
    font-family: $sans-roboto;
    font-size: rem(12px);
    color: var(--color-white);
    text-transform: uppercase;
    font-weight: $font__weight_regular;
    padding: rem(3px) rem(7px) rem(2px) rem(7px);
    border-radius: 11px;
  }

  &--new {
    background-color: var(--color-ace-red);
  }

  &--sponsored {
    background-color: var(--color-ace-yellow);
  }

  &--topic {
    background-color: rgba(218, 223, 225, 0.25);
    font-family: $sans-mulish;
    color: var(--color-ace-sky);
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    font-size: rem(15px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
  }
}
