@use '../../abstracts/' as *;
@use 'sass:math';

.tourtip {
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  box-sizing: border-box;
  color: rgb(31, 35, 37);
  max-width: 100%;
  position: relative;
  max-width: 380px;

  &__container {
    text-align: left;
    @include padding-top(rem(20px));
    @include padding-right(rem(30px));
    @include padding-bottom(rem(40px));
    @include padding-left(rem(20px));

    // &-title {}

    &-content {
      text-align: left;
      font-family: $sans-mulish;
      h4 {
        font-family:$sans-mulish;
        @include responsive-font-size(rem(24px));
        font-weight: $font__weight_bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: rem(-0.25px);
        color: $color-ace-black;
      }

      p {
        @include responsive-font-size(rem(15px));
        font-weight: $font__weight_regular;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        color: $color-ace-black;
        a {
          color: $color-ace-sky;
        }
      }

      &--challenges,
      &--pins {
        h4 {
          display: flex;
          align-items: center;
          .icon {
            margin-right: rem(5px);
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-ace-gray;
    @include padding-left(rem(20px));
  }

  &__progress {
    font-family: $sans-mulish;
    font-size: rem(15px);
    font-weight: $font__weight_regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: $color-ace-black;
  }

  &__primary-button {
    font-family: $sans-roboto;
    text-transform: uppercase;
    background-color: $color-ace-sky;
    color: $color-white;
    padding: rem(18px) rem(7px);
    min-width: rem(90px);
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }
}
