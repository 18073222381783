@use '/styles/abstracts' as *;
@use 'sass:math';

.modal {
  $self: &;
  position: fixed;
  overflow-y: auto;
  z-index: get-z-index('modal-backdrop');
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  &__inner {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }

  &__overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba($color-ace-black, 0.6);
    filter: blur(1px);
  }

  &__body {
    z-index: get-z-index('modal-body');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: rgba($color-white, 1);
    margin-left: auto;
    margin-right: auto;
    @include padding-top(rem(80px));
    @include padding-right(rem(80px));
    @include padding-bottom(rem(80px));
    @include padding-left(rem(80px));
    max-width: rem(880px);
    max-height: none;
    margin-right: 10px;
    margin-left: 10px;
    font-family: $sans-mulish;
    color: $color-ace-black;
  }

  &__x-btn {
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    background-color: transparent;
    padding: 0;
    color: $color-ace-black;
  }

  &__pre-title {
    @include responsive-font-size(rem(48px));
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: rem(-0.86px);
    text-align: center;
    color: var(--color-ace-black);
    &--emoji {
      font-family: $emoji;
    }
  }

  &__title {
    @include responsive-font-size(rem(48px));
    font-weight: $font__weight_light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: rem(-0.86px);
    text-align: center;
    margin-bottom: rem(20px);
  }

  &__desc {
    font-size: rem(18px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    @include margin-bottom(40px);
  }

  &__confirm-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include padding-top(rem(12.5px));
    @include padding-bottom(rem(12.5px));
    @include padding-right(rem(22px));
    @include padding-left(rem(22px));
    border-radius: rem(6px);
    background-color: $color-ace-sky;
    color: $color-white;
    font-family: $sans-roboto;
    @include responsive-font-size(rem(18px));
    font-weight: $font__weight_medium;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__footer {
    position: absolute;
    bottom: rem(35px);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  &__cancel-btn {
    font-family: $sans-mulish;
    font-size: rem(13px);
    background-color: transparent;
    padding: 0;
    color: $color-text-gray;
    text-decoration: underline;
    text-transform: none;
  }
}
