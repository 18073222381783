@use '/styles/abstracts/variables' as *;
// Animations

@mixin material-animation-fast-out-slow-in($duration: 0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation__curve_fast_out_slow_in;
}

@mixin material-animation-linear-out-slow-in($duration: 0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation__curve_linear_out_slow_in;
}

@mixin material-animation-fast-out-linear-in($duration: 0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation__curve_fast_out_linear_in;
}

@mixin material-animation-default($duration: 0.2s) {
  transition-duration: $duration;
  transition-timing-function: $animation__curve_default;
}
