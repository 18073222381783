@use '/styles/abstracts' as *;
@use 'sass:math';

.onboard {
  width: 100%;
  height: 100%;
  padding: rem(20px) rem(20px) rem(80px + 30px);
  max-width: rem($site__content_max_width);
  margin-left: auto;
  margin-right: auto;

  &__panel {
    width: 100%;
    height: 100%;
    &-inner  {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      &-content {
        //intentionally blank
      }
    }
  }

  &__panel-header {
    text-align: center;

    h2 {
      font-family: $sans-mulish;
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
      text-align: center;
      color: $color-ace-black;
      margin-bottom: rem(10px);
      @include responsive-font-size(rem(48px));
    }

    p {
      font-family: $sans-mulish;
      @include responsive-font-size(rem(18px));
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
      color: $color-ace-black;
    }
  }

  &__panel-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .grid {
      margin: rem(40px) auto auto;
    }
  }

  &-options-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    // grid-template-columns: repeat( auto-fit, minmax(rem(300px), 1fr) );
    grid-auto-rows: 1fr;
    grid-column-gap: rem(50px);
    grid-row-gap: rem(10px);
    margin: rem(40px) auto auto;
    @include mq(tablet_lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-options-stack {
    display: flex;
    flex-direction: column;
    row-gap: rem(10px);
    width: 100%;
    margin: rem(40px) auto auto;
  }

  &-options-grid,
  &-options-stack {
    &__button {
      font-family: $sans-mulish;
      @include responsive-font-size(rem(18px));
      border-radius: rem(48px);
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      text-transform: none;
      text-align: left;
      letter-spacing: -0.25px;
      color: $color-white;
      background-color: $color-ace-blue;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(20px) rem(20px) rem(20px) rem(30px);

      &[aria-checked='false'] &-icon {
        color: $color-ace-blue;
      }

      &[aria-checked='true'] {
        background-color: $color-ace-teal;
      }
      &[aria-checked='true'] &-icon {
        color: $color-white;
      }

      &-icon,
      &-text {
        display: flex;
        flex-direction: column;

        // &-label {}

        &-desc {
          display: flex;
          @include responsive-font-size(rem(18px));
        }

      }
      &-icon {
        order: 2;
      }

      &-text {
        order: 1;
        padding-right: rem(10px);
      }

      &:disabled[aria-checked='true'] {
        cursor: not-allowed;
        color: $color-white;
        background-color: $color-ace-gray;
        color: $color-text-gray;
      }

      &:disabled[aria-checked='true'] &-icon {
        // color: $color-ace-teal
      }
    }
  }
}
