@use '../abstracts/' as *;
@use 'sass:math';

.types-filter {
  $self: &;
  // position: relative;

  &__button {
    display: inline-flex;
    align-items: center;
    background: transparent;
    color: $color-ace-black;
    font-family: $sans-roboto;
    font-size: rem(15px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-left: 0;
    padding-right: 0;
    .icon {
      margin-left: rem(8px);
    }
    .icon {
      margin-left: rem(8px);
      @include material-animation-default;
    }
    &[aria-expanded='true'] {
      color: $color-ace-sky;
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &__panel {
    position: absolute;
    right: 0;
    transform: translateY(20px);
    z-index: get-z-index('types-filter-panel');
    display: flex;
    background: $color-white;
    box-shadow: 0 1px 20px 0 rgba(#000, 0.5);
    padding: rem(6px) rem(20px);

    .field-values-multi-select {
      display: flex;
      flex-direction: column;
    }
  }

  &-group-options {
    font-family: $sans-mulish;
    font-size: rem(15px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: $color-ace-sky;


    &__button {
      display: flex;
      background: transparent;
      padding: 0;
      margin: rem(13px) 0 rem(14px) 0;

      &-icon {
        margin-right: rem(16px);
      }
      &-text {
        color: $color-ace-sky;
        text-transform: none;
      }
    }
  }
}
