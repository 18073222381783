// ============================================================================
// 3. Typography
// ==============================================================================
// <link rel="preconnect" href="https://fonts.googleapis.com">
// <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
// <link href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&family=Roboto:wght@500;700&display=swap" rel="stylesheet">
// Below is from ACE website
// https://fonts.googleapis.com/css?family=Muli:200,300,400,400i,600,700|Roboto:400,500,700|Roboto+Condensed:400,700&display=swap
//---- Font Families
// Fallbacks found using: https://meowni.ca/font-style-matcher/
$sans-mulish: Mulish, sans-serif;
$sans-roboto: Roboto, sans-serif;
$emoji: emoji, sans-serif;
$monospace: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', -apple-system, monospace;
$monospace_alt: 'Courier 10 Pitch', Courier, -apple-system, monospace;
// TODO: Add font awesome
// FontAwesome Free Solid (900)

//---- Font Weights
$font__weight_light: 300;
$font__weight_regular: 400;
$font__weight_medium: 500;
// font__weight_semi_bold: 600;
$font__weight_bold: 700;

$font__base_family: $sans-mulish !default;
$font__base_size: 16px; // Always 16px
$font__base_size_min: 12px; // Always 16px
$font__base_weight: $font__weight_regular !default;
$font__base_line_height: 1.5 !default;


// <CODE>
$font__code: $monospace;
$font__code_size: 14px;

// <PRE>
$font__pre: $monospace_alt;
$font__pre_size: 14px;
$font__pre_line_height: 1.6;

// HEADINGS
// TODO: Cleanup typography variables to match natural language rather than h1-h6.
// Headline
// utility class: .headline-text
$font__size_headline: 80px;
$font__size_min_headline: $font__size_headline;
$font__line_height_headline: 1;
$font__weight_headline: $font__weight_light;
$font__family_headline: $sans-mulish;
$font__tracking_headline: null;
// Midline
// utility class: .typo-midline
$font__size_midhead: 56px;
$font__size_min_midhead: $font__size_midhead;
$font__line_height_midhead: 1;
$font__weight_midhead: $font__weight_light;
$font__family_midhead: $sans-mulish;
$font__tracking_midhead: null;
// Subhead
// utility class: .subhead-text
$font__size_subhead: 24px;
$font__size_min_subhead: $font__size_subhead;
$font__line_height_subhead: 1.167;
$font__weight_subhead: $font__weight_medium;
$font__family_subhead: $sans-mulish;
$font__tracking_subhead: 10;
// Subhead Title
// utility class: .typo-subhead-title
$font__size_subhead_title: 20px;
$font__size_min_subhead_title: $font__size_subhead_title;
$font__line_height_subhead_title:1;
$font__weight_subhead_title: $font__weight_regular;
$font__family_subhead_title: $sans-mulish;
$font__tracking_subhead_title: 10;
// Menu
// utility class: .menu-text
$font__size_menu: 18px;
$font__size_min_menu: $font__size_menu;
$font__line_height_menu: 1.111; // old 1.25
$font__weight_menu: $font__weight_medium;
$font__family_menu: $sans-mulish;
$font__tracking_menu: 10;

// Title
// utility class: .title-text
$font__size_title: 16px;
$font__size_min_title: $font__size_title;
$font__line_height_title: 1.25;
$font__weight_title: $font__weight_medium;
$font__family_title: $sans-mulish;
$font__tracking_title: 10;
// Label
// utility class: .title-text
$font__size_label: 13px;
$font__size_min_label: $font__size_label;
$font__line_height_label: 1.385;
$font__weight_label: $font__weight_medium;
$font__family_label: $sans-mulish;
$font__tracking_label: 10;
// Body Large
// utility class: .body-text-xl
$font__size_body_xl_min: 24px;
$font__size_body_xl_ipad:28px;
$font__size_body_xl_desktop_sm:30px;
$font__size_body_xl_desktop_md:32px;
$font__size_body_xl_desktop_lg:34px;
$font__size_body_xl_desktop_xl: 36px;
$font__size_body_xl: $font__size_body_xl_desktop_xl;
$font__line_height_body_xl: 1.467;
$font__weight_body_xl: $font__weight_regular;
$font__family_body_xl: $sans-roboto;
$font__tracking_body_xl: null;

// utility class: .body-text-lrg
$font__size_body_lrg_min: 20px;
$font__size_body_lrg_ipad:22px;
$font__size_body_lrg_desktop_sm:24px;
$font__size_body_lrg_desktop_md:26px;
$font__size_body_lrg_desktop_lg:28px;
$font__size_body_lrg_desktop_xl: 30px;
$font__size_body_lrg: $font__size_body_lrg_desktop_xl;
$font__line_height_body_lrg: 1.467;
$font__weight_body_lrg: $font__weight_regular;
$font__family_body_lrg: $sans-roboto;
$font__tracking_body_lrg: null;
// Body Medium
// utility class: .typo-body-md
$font__size_body_md: 18px;
$font__size_min_body_md: $font__size_body_md;
$font__line_height_body_md: 1.66667;
$font__weight_body_md: $font__weight_regular;
$font__family_body_md: $sans-roboto;
$font__tracking_body_md: null;
// Body
// utility class: .body-text
$font__size_body: 16px;
$font__size_min_body: $font__size_body;
$font__line_height_body: 1.5;
$font__weight_body: $font__weight_regular;
$font__family_body: $sans-mulish;
$font__tracking_body: 10;
// Micro
// utility class: .micro-text
$font__size_micro: 13px;
$font__size_min_micro: $font__size_micro;
$font__line_height_micro: 1.5;
$font__weight_micro: $font__weight_regular;
$font__family_micro: $sans-mulish;
$font__tracking_micro: 10;
// Legal
// utility class: .legal-text
$font__size_legal: 13px;
$font__size_min_legal: $font__size_legal;
$font__line_height_legal: 1.538;
$font__weight_legal: $font__weight_regular;
$font__family_legal: $sans-mulish;
$font__tracking_legal: 10;


// <dt>
$font__dt_weight: $font__weight_medium !default;
