@use '../abstracts/' as *;
@use 'sass:math';

.new-filter {
  $self: &;
  display: inline-flex;
  align-items: center;
  background: transparent;
  font-family: $sans-mulish;
  font-size: rem(15px);
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: $color-ace-black;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
  margin-right: rem(30px);
  .icon {
    margin-left: rem(8px);
  }


  &__label {
    transform: translateY(rem(-2px));
  }
}
