@use '../../abstracts/' as *;
@use 'sass:math';

.hero-course {
  $self: &;
  background-color: var(--color-ace-red);

  &__container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    @include mq(desktop_sm) {
      flex-direction: row;
    }
  }

  &__details,
  &__img {
    width: 100%;
    @include mq(desktop_sm) {
      width: 50%;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: rem(30px);
    color: var(--color-white);
    justify-content: space-between;
    order: 2;
    @include mq(desktop_sm) {
      order: 1;
    }
  }

  &__details-header {
    padding-bottom: rem(20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-size: rem(15px);
    font-weight: $font__weight_regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;

    &-duration {
      display: flex;
      align-items: center;

      .icon {
        margin-right: rem(11px);
      }
    }

    &-credits {
    }
  }

  &__details-body {
    padding-top: rem(25px);
    padding-bottom: rem(25px);
    margin-top: auto;
    &-title {
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
      @include responsive-font-size(rem(48px));
    }
  }

  &__details-footer {
    margin-top: auto;

    &-link {
      color: inherit;
      font-family: $sans-roboto;
      font-size: rem(15px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-decoration: none;
      text-transform: uppercase;

      .icon {
        transform: translate(rem(4px), rem(-10px));
      }
    }
  }

  &__img {
    position: relative;
    height: 50vw;
    order: 1;
    @include mq(desktop_sm) {
      order: 2;
    }
    @include mq(desktop_md) {
      height: auto;
      padding-bottom: 0.5 * to-percent(420, 720);
    }
  }
}
