@use 'sass:color';
@use 'sass:map';
@use 'sass:math';
@use 'color-palette' as *;
@use 'typography' as *;

// sass-lint:disable no-css-comments, space-before-colon, no-trailing-zero
// ============================================================================
// Table of Contents
//   1. Content Sizing
//   2. Directory Paths & Z-Indexes
//   3. Typography
//   4. Colors
//   5. Module & Component Specific
// ==============================================================================
$gutter__mobile: 20px;
$gutter__desktop: 20px;
// width of design file canvas
$site__max_width: 1440px;
$site__content_max_width: 1220px;
$site__content_max_width_plus_gutters: $site__content_max_width + ($gutter__desktop * 2);
$site__header_height_desktop: 80px;
$site__header_height_mobile: 60px;
// ============================================================================
// Directory Paths
// ==============================================================================
//---- Images
$directory__images: '../img/';

$focus__outline_color: rgba($color-ace-sky, 1);
$focus__outline_style: 1px solid $focus__outline_color;
$focus__outline-offset: 2px;
// Body
//
// Settings for the `<body>` element.
$body__background_color: $color-white !default;
$body__text_color: $color-ace-black !default;
$headline__text_color: $color-ace-black !default;

// Links
//
// Style anchor `<a>` elements.

$link__color: $color-ace-sky !default;
$link__color_hover: color.adjust($link__color, $lightness: -15%) !default;
$link__decoration: none !default;
$link__decoration_hover: underline !default;
// Borders
$border__width: 1px !default;
$border__color: $color-ace-gray;

//  Horizontal Rules
// Style anchor `<hr>` elements.
$hr__border_width: 1px !default;
$hr__color: $color-ace-gray;

//  Selections
$selection__color: initial;
$selection__color_background: #b4d5fe;

// ============================================================================
// 5. Module/Component Settings
// ==============================================================================
// ---- Site Nav

//  <BUTTON>
// ==========================================================================
$button__font_family: $sans-mulish;
$button__font_size: 14px;
$button__font_weight: $font__weight_medium;
$button__line_height: 1;
$button__color_text: $color-white;
$button__color_text_hover: $color-white;
$button__background_color: $color-ace-sky;
$button__background_color_hover: $color-ace-sky;
$button__border_radius: 3px;
$button__color_text_disabled: $color-ace-light-gray;
$button__background_color_disabled: $color-ace-gray;
$button__padding: 11.5px 14px 8.5px 14px;
$button__focus_outline_width: thin;
$button__focus_outline_color: $color-white;
$button__focus_outline_style: dotted;
$button__focus_outline: $button__focus_outline_width
$button__focus_outline_style $button__focus_outline_color;
$button__text_transform: uppercase;

$form__input_padding_top: 16px;
$form__input_padding_right: 14px;
$form__input_padding_bottom: 12px;
$form__input_padding_left: 14px;
$form__input_padding: $form__input_padding_top $form__input_padding_right
$form__input_padding_bottom $form__input_padding_left;
// Headings

$text__margin_bottom_normal: $gutter__mobile * 0.5;

// Paragraphs
// Style p element.
$paragraph__margin_bottom: 10px;
$paragraph__font_family: $sans-mulish;

// Horizontal Rules <hr>
$spacing__hr_mobile: $gutter__mobile * 1.5 - ($hr__border_width * 0.5);
$spacing__hr_desktop: $gutter__desktop * 1.5 - ($hr__border_width * 0.5);

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table__bg: transparent !default;
$table__border_width: $border__width !default;

$table__font_family: $sans-mulish;
$table__caption_side: top;
$table__head_bg: $color-ace-sky !default;
$table__head_color: $color-white !default;
$table__head_cell_padding_vertical: rem(1px) !default;
$table__body_color: $color-ace-sky !default;
$table__body_cell_bg: $color-white !default;
$table__cell_padding_vertical: $gutter__mobile - 4px !default;
$table__cell_padding_horizontal: rem(1px) !default;
$table__cell_padding_sm: 0.3rem !default;
$table__striped_order: odd !default;

// ============================================================================
// Transitions & Animations
// ============================================================================
// From Material Design Lite
// https://github.com/google/material-design-lite
// src/_variables.scss#L497
$animation__curve_fast_out_slow_in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation__curve_linear_out_slow_in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation__curve_fast_out_linear_in: cubic-bezier(0.4, 0, 1, 1) !default;
$animation__curve_default: $animation__curve_fast_out_slow_in !default;

$transition__default_duration: 0.4s;
$transition__default_function: cubic-bezier(0.46, 0.01, 0.32, 1);
$transition__default: $transition__default_duration
  $transition__default_function;

// sass-lint enable no-css-comments, space-before-colon, no-trailing-zero
