@use '/styles/abstracts' as *;
@use 'sass:math';
.card-thumb {
  $self: &;
  width: 100%;
  position: relative;
  display: block;

  // * {@include debug;}
  &__img-wrap {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: aspect-ratio-fixed(464, 260);
  }

  &__tags {
    position: absolute;
    height: 10px;
    top: 6px;
    left: 6px;
    right: 6px;
    z-index: get-z-index('card-thumb-tags');

    .tag {
      margin-right: rem(4px);
      line-height: 1;
    }
  }

  &__play-icon {
    position: absolute;
    top: 0;
    width: 76px;
    height: 76px;
    top: calc(50% - 38px);
    left: calc(50% - 38px);

    .circle {
      fill: rgba(31, 35, 37, 0.5);
      stroke: none;
    }
    .triangle {
      fill: rgba(255, 255, 255, 1);
    }
  }
}
