@use '../../abstracts/' as *;
@use 'sass:math';

.article-button {
  display: inline-block;
  margin-top: rem(60px);
  margin-bottom: rem(30px);
  min-width: 236px;
  min-height: 52px;
  padding: rem(10px) rem(22px);
  border-radius: rem(6px);
  font-family: $sans-roboto;
  font-size: rem(18px);
  font-weight: $font__weight_medium;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: var(--color-white);
  text-decoration: none;
  text-transform: uppercase;

  .icon {
    margin-left: rem(12px);
  }

  &--red {
    background-color: var(--color-ace-red);
    border-color: var(--color-ace-red);
  }

  &--blue {
    background-color: var(--color-ace-sky);
    border-color: var(--color-ace-sky);
  }
}
