@use "../../abstracts/" as *;
@use "sass:math";

.article-author {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include mq(desktop_sm) {
    flex-direction: row;
  }
  @include mq(1220px) {
    max-width: 1220px;
  }
  &__rail {
    padding-left: rem(20px);
    padding-top: rem(40px);
    @include mq(desktop_sm) {
      padding-right: rem(30px);
      padding-left: 0;
      width: math.div(340, 1220) * 100%;
    }
  }

  &__content {
    padding-right: rem(20px);
    padding-left: rem(20px);
    padding-top: rem(40px);

    @include mq(desktop_sm) {
      padding-right: 0;
      padding-left: rem(30px);
      width: 100 - math.div(340, 1220) * 100%;
    }
  }
}
