@use '../../abstracts/' as *;
@use 'sass:math';

.article-header {
  $self: &;
  position: relative;
  margin-bottom: rem(20px);
  @include mq(desktop_sm) {
    margin-bottom: rem(30px);
  }

  &__title {
    @include responsive-font-size(rem(48px));
    font-weight: $font__weight_light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    margin-bottom: 0;
    letter-spacing: rem(-0.86px);
    color: var(--color-ace-black);
  }

  &__description {
    margin-top: rem(15px);
  }

  &__pin-button {
    position: absolute;
    right: rem(-10px);
    top: 0;
  }

  // saving just in case :)
  // + p,
  // + .article-audio-embed,
  // + .article-resources,
  // + .author,
  // + .tag-group {
  //   margin-top: rem(20px);
  //   @include mq(desktop_sm) {
  //     margin-top: rem(30px);
  //   }
  // }
}
