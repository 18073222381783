@use '/styles/abstracts' as *;
@use 'sass:math';
.completion {
  $self: &;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: rem(9px) rem(10px);
  color: $color-white;
  background-color: $color-ace-purple;

  .icon {
    margin-right: rem(8px);
    .checkbox {
      fill: rgba($color-white, 0);
      stroke: rgba($color-white, 1);
    }
    .checkmark {
      fill: rgba($color-ace-teal, 0);
    }
  }

  &--complete {
    background-color: $color-ace-teal;
    .icon {
      .checkbox {
        fill: rgba($color-white, 1);
        stroke: rgba($color-white, 1);
      }
      .checkmark {
        fill: rgba($color-ace-teal, 1);
      }
    }

    &__text {
      font-family: $sans-roboto;
      font-size: rem(15px);
      font-weight: $font__weight_medium;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  @include mq(mobile_xs) {
  }
  @include mq(mobile_sm) {
  }
  @include mq(mobile_md) {
  }
  @include mq(mobile_lg) {
  }
  @include mq(tablet_sm) {
  }
  @include mq(tablet_md) {
  }
  @include mq(tablet_lg) {
  }
  @include mq(desktop_sm) {
  }
  @include mq(desktop_md) {
  }
  @include mq(desktop_lg) {
  }
  @include mq(desktop_xl) {
  }
}
