@use '../abstracts/' as *;
@use 'sass:math';
// needs higher specificity because this is a child
// of .article-body and raw selectors are styled in it
.resource-links {
  $self: &;
  padding: 0;
  background-color: transparent;
  margin: 0;
  text-indent: none;
  list-style: none;
  border-radius: 0;

  li {
    font-family: $sans-roboto;
    font-size: rem(15px);
    font-weight: $font__weight_medium;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    margin: 0 0 rem(22px) 0;

    &:first-child {
      margin-top: rem(22px);
    }

    &:last-child {
      margin-bottom: rem(22px);
    }

    #{$self}__link {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--color-ace-sky);

      &-icon {
        display: inline-flex;
        // flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        min-width: rem(20px);
        min-height: rem(21px);
        margin-right: rem(11.9px);
      }

      &-label {
        display: inline-flex;
      }
    }
  }
}
