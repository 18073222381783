@use '../abstracts/' as *;
@use 'sass:math';

.hr {
  width: calc(100% - rem(40px));
  max-width: calc(rem($site__content_max_width) - rem(40px));
  height: rem(1px);
  padding: 0 rem(20px) 0;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: $color-ace-gray;
  background-clip: content-box;

  &-top-spacing {
    @include margin-top(rem(80px));
  }

  &-bottom-spacing {
    @include margin-bottom(rem(60px));
  }

  &-top-bottom-spacing {
    @extend .hr;
    @extend .hr-top-spacing;
    @extend .hr-bottom-spacing;
  }


  &.ghost {
    visibility: hidden;
  }
}
