@use '../../abstracts/' as *;
@use 'sass:math';

.article-completion {
  $self: &;
  position: sticky;
  top: rem(240px);
  @include safari-only {
    top: rem(100px);
  }
  border-radius: rem(6px);
  overflow: hidden;
  box-shadow: 0 rem(2px) rem(10px) 0 rgba(0, 0, 0, 0.25);
  background-color: $color-white;
  margin-top: rem(30px);
  margin-bottom: rem(30px);
  display: none;
  @include mq(desktop_sm) {
    display: block;
  }
  &::before {
    content: '';
  }

  &__body {
    padding: rem(17px);
  }

  &__title {
    font-size: rem(24px);
    font-weight: $font__weight_light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: rem(-0.25px);
    color: var(--color-ace-black);
  }

  &__desc {
    font-size: rem(15px);
    font-weight: $font__weight_regular;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: var(--color-ace-black);
  }

  &-mobile.completion {
    display: flex;
    margin: 0;
    position: fixed;
    z-index: get-z-index('article-completion-mobile');
    left: 0;
    right: 0;
    bottom: 0;
    @include mq(desktop_sm) {
      display: none;
    }
  }
}
