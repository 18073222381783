@use '../abstracts/' as *;
@use 'sass:math';

.explore {
  $self: &;
  max-height: rem(400px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: $color-white;
  @include padding-top(rem(60px));
  @include padding-right(rem(20px));
  @include padding-bottom(rem(80px));
  @include padding-left(rem(20px));
  @include mq(tablet_lg) {
    @include padding-bottom(rem(118px));
  }

  &__header {
    @include margin-bottom(rem(30px));

    h1 {
      @include responsive-font-size(rem(48px));
      font-weight: $font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: rem(-0.86px);
    }

    p {
      max-width: rem(676px);
      @include responsive-font-size(rem(15px));
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
    }

  }

  &__content {
    @include padding-top(rem(60px));
    @include padding-right(rem(20px));
    margin-bottom: 0;
    @include padding-left(rem(20px));

    &--results {
      min-height: rem(600px); // stop page jumping from filters (same height as a card
    }

    &:last-child {
      @include margin-bottom(rem(80px));
    }
  }

  &__content-header {
    h2 {
      @include responsive-font-size(rem(48px));
      font-weight:$font__weight_light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem(-0.86px);
      color: $color-ace-black;
    }
  }

}
